import { InputHTMLAttributes } from "react";
import InputMask from "react-input-mask";
import styled from "styled-components";

const StyledTextInput = styled(InputMask)`
  height: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px; /* ajuste conforme necessário */

  width: 100%;
  border: 2px solid transparent;
  border-bottom: 1px solid gray;
  background: #f4f4f4;
  color: #333333; /* ajuste conforme necessário */
  font-family: "IBM Plex Sans", sans-serif;
  &::placeholder {
    color: #9b9898;
  }
  font-size: 14px;
  line-height: 18px;
  outline: none;

  &:focus {
    border: 2px solid #0072c6; /* ajuste conforme necessário */
  }
`;

const MaskedTextInputDumbo = (
  props: InputHTMLAttributes<HTMLInputElement> & { mask: string }
) => {
  const { mask, placeholder, onChange, value, id, name } = props;
  return (
    <StyledTextInput
      mask={mask}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      id={id}
      name={name}
    />
  );
};

export default MaskedTextInputDumbo;
