import { TableBasic, Tag } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo } from "react"
import useForum from "~/hooks/useForum"
import { ModalInviteParticipant } from "../CreatForum/ModalInviteParticipant"
import useLoading from "~/hooks/useLoading"
import { getForumVoteUser, getTypeUserRole } from "~/services/api/forum"
import useUser from "~/hooks/useUser"
import { useLocation } from "react-router-dom"
import { formatarDataISO } from "~/services/utils"

const status_name_vote: { [key: string]: JSX.Element } = {
  aprovado:
    <div className="w-[120px]">
      <Tag position="center" type="green">
        <p className="p-1 m-0">Aprovar</p>
      </Tag>
    </div>,
  reprovado:
    <div className="w-[120px]">
      <Tag position="center" type="red">
        <p className="p-1 m-0">Rejeitar</p>
      </Tag>
    </div>,
  vetado:
    <div className="w-[120px]">
      <Tag position="center" type="red">
        <p className="p-[6px] m-0">Vetado</p>
      </Tag>
    </div>,
  pendente_de_votacao:
    <div className="w-[120px]">
      <Tag position="center" type="border">
        <p className="p-[6px] m-0">Condicionado</p>
      </Tag>
    </div>
}

const status_name: { [key: string]: JSX.Element } = {
  voto_pendente:
    <div className="w-[120px]">
      <Tag position="center" type="border">
        <p className="p-1 m-0">Voto Pendente</p>
      </Tag>
    </div>,
  voto_realizado:
    <div className="w-[120px]">
      <Tag position="center" type="inverse">
        <p className="p-[9px] m-0">Voto Realizado</p>
      </Tag>
    </div>,
  vetado:
    <div className="w-[120px]">
      <Tag position="center" type="border">
        <p className="p-[9px] m-0">Vetado</p>
      </Tag>
    </div>
}

const voteDict: { [key: string]: string } = {
  vota: "Vota",
  nao_vota: "Não Vota",
  voto_com_veto: "Vota com Veto"
}

export const Participants = () => {

  const { userProfile } = useUser();

  const location = useLocation();

  const { id } = location.state || {};

  const {
    setmodalInviteParticipant,
    modalInviteParticipant,
    participantList,
    listForumFiltered,
    setParticipantList,
    listForumVoteUser,
    setlistForumVoteUser,
    setVote,
  } = useForum()

  const { setLoading } = useLoading()

  const fetchParticipantMandatoryForum = useCallback(async () => {

    setLoading(true);

    try {

      const { data } = await getTypeUserRole(listForumFiltered?.forum_type?.id)

      setParticipantList(data.results)

    } catch {

      setParticipantList([])

    } finally {

      setLoading(false);
    }

  }, [participantList, listForumFiltered])


  const fetchForumVote = useCallback(async () => {

    setLoading(true);

    try {
      const { data } = await getForumVoteUser(id)

      setlistForumVoteUser(data.results)

    } catch {

    } finally {

      setLoading(false);
    }
  }, [listForumVoteUser])

  const listForumVote = useMemo(() => {
    return listForumVoteUser?.flatMap((forum) => {
      return forum?.forum_type_user_roles?.filter((userRole) => {
        return forum.user === userRole.user.id;
      }).map((userRole) => {
        return {
          ...userRole,
          vote_decision: forum.vote_decision,
          vote_status: forum.vote_status,
          forum: forum.forum,
          vote_date: forum.updated_on
        };
      });
    });
  }, [listForumVoteUser]);

  const listFilteredParticipants = useMemo(() => {
    return participantList?.map((participant) => {
      const forum = listForumVote?.find((forum) =>
        forum.user.id === participant.user.id && forum.forum === Number(listForumFiltered?.id));
      return {
        ...participant,
        vote_decision: forum ? forum.vote_decision : "",
        vote_status: forum ? forum.vote_status : "",
        forum: forum?.forum,
        vote_date: forum?.vote_date
      };
    });
  }, [participantList, listForumVote]);

  const renderlistForum = useMemo(() => {
    return listFilteredParticipants?.map((forum) => ({
      "Nome do participante": `${forum?.user?.first_name} ${forum?.user?.last_name}`,
      "Tipo de voto": voteDict[forum?.type_vote],
      "Status":
        forum?.type_vote === "nao_vota" ?
          "" : status_name[forum?.vote_status] ||
          <div className="w-[120px]">
            <Tag position="center" type="border">
              <p className="p-[6px] m-0">Voto pendente</p>
            </Tag>
          </div>,
    }))
  }, [participantList, listForumVote])


  const renderListForumClosed = useMemo(() => {
    return listFilteredParticipants?.map((forum) => {
      const isPendingVote =
        (forum?.type_vote === "vota" || forum?.type_vote === "voto_com_veto") &&
        forum?.vote_decision === "";

      return {
        "Nome do participante": `${forum?.user?.first_name} ${forum?.user?.last_name}`,
        "Tipo de voto": voteDict[forum?.type_vote],
        "Vote": status_name_vote[forum?.vote_decision] || (
          isPendingVote && (
            <div className="w-[120px]">
              <Tag position="center" type="border">
                <p className="p-[5px] m-0">Voto Pendente</p>
              </Tag>
            </div>
          )
        ),
      };
    });
  }, [participantList, listFilteredParticipants]);

  const isUserInvited = listFilteredParticipants?.some(
    (participant) => participant.user.id === userProfile?.user.id
  );

  useEffect(() => {
    fetchParticipantMandatoryForum()
    fetchForumVote()
  }, [])

  useEffect(() => {
    const hasVoted = listFilteredParticipants?.some((user) => (
      user.user.id === userProfile?.user.id && user.vote_status === "voto_realizado"
      && listForumFiltered?.forum_type?.id === user.forum
    ));
    setVote(hasVoted || false);

  }, [listFilteredParticipants, userProfile?.user.id]);

  return (
    <div>
      <TableBasic
        search={listForumFiltered?.forum_status !== "encerrado" || !isUserInvited}
        columnTitles={
          [
            "Nome do participante",
            "Tipo de voto",
            listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado" ? "Voto" : "Status",
          ]
        }
        pageSizes={[6]}
        buttons={
          listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado" || !isUserInvited
            ?
            [] :
            [
              {
                size: "small",
                label: "Convidar participante",
                kind: "primary",
                position: "right",
                dimension: "37",
                onClick: () => setmodalInviteParticipant(true)
              }
            ]}
        rowData={
          (listForumFiltered?.forum_status === "encerrado" || listForumFiltered?.forum_status === "vetado") && renderListForumClosed
            ? renderListForumClosed
            : renderlistForum || []
        }
      />
      <div className="w-[95%]">
        <h5 className="mt-[40px] mb-[50px]">Registros</h5>
        {listFilteredParticipants?.map((list) => {
          return (
            list.vote_status && (
              <div>
                <div className="flex gap-[10px] items-center my-[20px]">
                  <h4 className="text-[18px] m-0 p-0" key={list?.user?.id}>
                    {list?.user?.first_name}  {list?.user?.last_name}
                  </h4>
                  <p className="m-0 p-0">registrou o voto</p>
                  <p className="m-0 p-0">{list?.vote_date && formatarDataISO(list?.vote_date)}</p>
                </div>
                <div className="bg-[#c6c6c6] w-[100%] h-[1px]"></div>
              </div>
            )
          )
        })}
        <div>
          <div className="flex gap-[10px] items-center my-[20px]">
            <h4 className="text-[18px] m-0 p-0" >
              {listForumFiltered?.name}
            </h4>
            <p className="m-0 p-0">Alterou o status para</p>
            <h4 className="text-[18px] m-0 p-0" >{listForumFiltered?.forum_status}</h4>
            <h4 className="text-[18px] m-0 p-0" >{formatarDataISO(String(listForumFiltered?.updated_on))}</h4>
          </div>
          <div className="bg-[#c6c6c6] w-[100%] h-[1px]"></div>
        </div>
      </div>
      <ModalInviteParticipant
        forum_type={listForumFiltered && String(listForumFiltered?.forum_type?.id)}
        open={modalInviteParticipant}
        onClose={() => setmodalInviteParticipant(false)} />
    </div>
  )
}
