import React from 'react';
import styled from 'styled-components';
import { arrowButtonIcon } from '~/assets/IconsSVG';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const PaginationContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
  gap: 20px;
  font-family:"IBM Plex Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.16px;
`;

const PageButton = styled.button<{ active?: boolean }>`
  padding: 1rem 1rem;
  //border-bottom: ${({ active }) => (active ? '4px solid #2370ff' : '')};
  color: ${({ active }) => (active ? '#000000' : '#000000')};
  border-radius: 0px;
  position:"relative";
  cursor: pointer;
  &:disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
    opacity: .5;
  }
  border-bottom: ${({ active }) => (active ? '3px solid #2370ff' : '')};
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 4px;
//background-color: ${({ active }) => (active ? '#2370ff' : 'transparent')};
  }
`;

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
    const handlePageChange = (page: number) => {
        if (page < 1 || page > totalPages) return;
        onPageChange(page);
    };

    const getVisiblePages = () => {
        const maxPagesToShow = 10;
        const half = Math.floor(maxPagesToShow / 2);
        let start = Math.max(currentPage - half, 1);
        let end = Math.min(start + maxPagesToShow - 1, totalPages);

        if (end - start < maxPagesToShow - 1) {
            start = Math.max(end - maxPagesToShow + 1, 1);
        }

        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    const visiblePages = getVisiblePages();

    return (
        <PaginationContainer>
            <PageButton style={{ transform: "rotate(180deg)" }} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                {arrowButtonIcon}
            </PageButton>
            {visiblePages.map((page) => (
                <PageButton
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </PageButton>
            ))}
            <PageButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                {arrowButtonIcon}
            </PageButton>
        </PaginationContainer>
    );
};

export default Pagination;
