import { Breadcrumb, Button, Input, Tab, TabPane } from "prosperita-dumbo-react";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import { getForumFilteredAPI, postForumTopicAPI } from "~/services/api/forum";
import userIcon from "~/assets/User.png";
import { formatCPFCNPJ } from "~/services/utils";
import useUser from "~/hooks/useUser";
import { useFormik } from "formik";
import useNotification from "~/hooks/useToast";
import { sendIconSVG } from "~/assets/IconsSVG"

const initialValues = {
    forum_description: "",
    title_forum: ""
}

export const CreateNewTopic = () => {

    const { setLoading } = useLoading()

    const { setNotification, closeNotification } = useNotification()

    const navigate = useNavigate()

    const location = useLocation();

    const { id } = location.state || {};

    const {
        userProfile,
    } = useUser();

    const {
        listForumFiltered,
        setListForumlistForumFiltered
    } = useForum()

    const { handleChange, values } = useFormik(({
        initialValues,
        onSubmit: () => { }
    }))

    const fetchForumAPI = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await getForumFilteredAPI(id)

            setListForumlistForumFiltered(data)

        } catch {

        } finally {

            setLoading(false)
        }

    }, [listForumFiltered, id])

    const fetchCreteForum = useCallback(async () => {

        setLoading(true)

        try {

            await postForumTopicAPI({
                content: values.forum_description,
                forum: Number(id),
                title: values.title_forum,
                user: userProfile?.id || 0
            })
            setNotification({
                status: "success",
                message: "O tópico foi criado com sucesso.",
                title: "Sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            navigate("/app/forum/activities/", { state: { id: listForumFiltered?.id } })

        } catch {
            setNotification({
                status: "error",
                message: "Não foi possível criar o tópico. Por favor, tente novamente.",
                title: "Erro ao criar tópico",
                actived: true,
                onClose: () => closeNotification()
            });
        } finally {

            setLoading(false)
        }
    }, [id, values, userProfile])

    useEffect(() => {
        fetchForumAPI()
    }, [id])

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Painel", onClick: () => "" },
                                    { crumb: "Fórum" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            #{listForumFiltered?.id} - {listForumFiltered?.name}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[95%] mx-auto mt-[50px]">
                <Tab width="95%">
                    <TabPane
                        width="100px"
                        position="center"
                        label="Tópicos"
                        isActive
                        bordercolor=" rgb(10, 74, 135)"
                        onClick={() => { }}>
                        <div className="w-[100%] mt-[20px]">
                            <div className="flex items-center m-0 p-0 gap-[22px] w-[100%]">
                                <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                                    <img src={userIcon} />
                                </div>
                                <div className="section m-0 p-0">
                                    <h2
                                        style={{ fontFamily: "IBM Plex Sans" }}
                                        className="text-[18px] m-0 font-[900]"
                                    >

                                        {userProfile?.name}
                                    </h2>
                                    <h4
                                        style={{ fontFamily: "IBM Plex Sans" }}
                                        className="text-[17px] font-[400] m-0 mt-1"
                                    >
                                        {formatCPFCNPJ(String(userProfile?.document_number))}
                                    </h4>
                                </div>
                                <div style={{ borderRadius: "30px" }} className="section m-0 p-1 bg-[#c6c6c6]">{"Rascunho"}</div>
                            </div>
                            <div className="w-[100%] mt-[20px]">
                                <Input type="text"
                                    size="large"
                                    label="Tópico"
                                    onChange={handleChange}
                                    name="title_forum"
                                    placeholder="Digite aqui o título do tópico" />
                                <Input
                                    type="textarea"
                                    cols={500}
                                    rows={15}
                                    name="forum_description"
                                    onChange={handleChange}
                                    placeholder="Exemplo de Rich text format adicionado ao Text field"
                                />
                            </div>
                            <div className="w-[100%] flex justify-end items-center mb-[50px] mt-[20px]">
                                <div>
                                    {/* <Dropdown
                                        kind="fluid"
                                        options={[]}
                                        size="large"
                                        placeholder="Pergunta"
                                        label="Adicionar como"
                                    /> */}
                                </div>
                                <Button
                                    label="Enviar"
                                    kind="primary"
                                    size="extraLarge"
                                    hasIconSvg
                                    icon={sendIconSVG}
                                    noPadding="20px 90px 30px 10px"
                                    onClick={() => fetchCreteForum()}

                                />
                            </div>
                        </div>
                    </TabPane>
                </Tab>
            </div>
        </div>
    );
};
