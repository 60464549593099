import { Breadcrumb, Button, TableBasic } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import EmptyStateIcon from "~/assets/EmptyState.png"
import Margin from "~/components/Margin"
import editIcon from "~/assets/editicon.png"
// import viewIcon from "~/assets/viewIcon.png"
import trashIcon from "~/assets/Trash-can.png"
import { DeleteTypeForum } from "./DeleteTypeForum"
import useForum from "~/hooks/useForum"
import { ModalEditTypeForum } from "./ModalEditTypeForum"
import { getTypeForum, getTypeUsersRoles } from "~/services/api/forum"
import useLoading from "~/hooks/useLoading"
import { formatarDataISO } from "~/services/utils"

export const TypeForumList = () => {

    const navigate = useNavigate()

    const { setLoading } = useLoading();

    const {
        deleteForumModal,
        setDeleteForumModal,
        editForumModal,
        setEditForumModal,
        setlistTypeForum,
        listTypeForum,
        setdeleteTypeForumdata,
        setEditTypeForum
    } = useForum()


    const fecthTypeForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeForum()

            setlistTypeForum(data.results)

        } catch {

        } finally {

            setLoading(false);
        }
    }, [listTypeForum])


    const dataForum = useMemo(() => {

        return listTypeForum?.map((data) => ({
            "Tipo de Forum": data.name,
            "Data de Criação": formatarDataISO(data.created_at),
            "Última atualização": formatarDataISO(data.updated_at),
            "editar":
                <div className="w-[30px]">
                    <Button
                        kind="ghost"
                        size="small"
                        noPadding="2px"
                        icon={editIcon}
                        onClick={() => {
                            setEditTypeForum(data)
                            setEditForumModal(true)
                        }
                        }
                    />
                </div>,
            "deletar":
                <div className="w-[30px]">
                    <Button
                        kind="ghost"
                        size="small"
                        noPadding="2px"
                        icon={trashIcon}
                        onClick={() => {
                            setDeleteForumModal(true)
                            setdeleteTypeForumdata(data)
                        }}
                    />
                </div>,
        }))

    }, [listTypeForum])

    useEffect(() => {
        fecthTypeForum()
        getTypeUsersRoles()
    }, [])

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Configurações", onClick: () => navigate("/app") },
                                    { crumb: "Fórum" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            Tipos de fórum
                        </h2>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-[50]">
                    {dataForum?.length === 0 ?
                        <div className="w-[92%] mx-auto mt-[40px]">
                            <div className="flex justify-end">
                                <Button
                                    kind="primary"
                                    size="large"
                                    label="Criar novo tipo de fórum"
                                    onClick={() => navigate("/app/forum/new-type-forum/")}
                                />
                            </div>
                            <div className="mb-4">
                                <img src={EmptyStateIcon} />
                            </div>
                            <Margin mt={40} />
                            <h6 className="text-[20px] not-italic font-normal leading-[28px]">Não há nenhum tipo de fórum criado</h6>
                            <Margin mt={15} />
                            <h5 className="text-[15px] not-italic font-normal leading-[28px]">Não há tipos de fóruns para serem exibidos no momento,
                                para criar um novo tipo de fórum clique no botão
                                direito localizado no canto superior.</h5>
                        </div> :
                        <div className="mt-[50px] w-[95%] mx-auto">
                            <TableBasic
                                search
                                buttons={[
                                    {
                                        kind: "primary",
                                        label: "Criar novo tipo de fórum",
                                        size: "small",
                                        dimension: "37",
                                        position: "right",
                                        onClick: () => navigate("/app/forum/new-type-forum/")
                                    }
                                ]}
                                columnTitles={
                                    [
                                        "Tipo de Forum",
                                        "Data de Criação",
                                        "Última atualização",
                                        "",
                                        "",
                                    ]
                                }
                                pageSizes={[7, 5, 20]}
                                rowData={dataForum || []}
                            />
                        </div>
                    }
                </div>
            </div>
            <DeleteTypeForum
                open={deleteForumModal}
                onClose={() => setDeleteForumModal(false)}
            />
            <ModalEditTypeForum
                open={editForumModal}
                onClose={() => setEditForumModal(false)}
            />
        </div>
    )
}
