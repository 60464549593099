import { Modal } from "prosperita-dumbo-react";
import { ContainedList, ContainedListItem } from "@carbon/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Search } from "carbon-components-react";
import useProfile from "~/hooks/useProfile";
import useLoading from "~/hooks/useLoading";
import { getProfilesAPI } from "~/services/api/profile";
import styled from "styled-components";
import userIcon from "~/assets/User.png";
import { getTypeUserRole, postTypeUserRole } from "~/services/api/forum";
import useNotification from "~/hooks/useToast";
import useForum from "~/hooks/useForum";


type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum_type?: string
}

const CustomSearch = styled(Search)`
  .bx--search-input {
    background-color: #080303;  // Altere a cor de fundo conforme necessário
    color: #333;  // Altere a cor do texto
  }

  .bx--search-close {
    color: #ff6347;  // Altere a cor do ícone de fechar (exemplo: tomate)
  }

  .bx--label {
    color: #0073e6;  // Altere a cor do texto da label
  }

  .bx--search-magnifier {
    fill: #0073e6;  // Altere a cor do ícone de lupa
  }
`;

const StyledContainedListItem = styled(ContainedListItem) <{ selected: boolean }>`
  cursor: pointer;
  border: ${(props) => (props.selected ? "1px solid #0073e6" : "2px solid transparent")};
  border-radius: 4px;
  
  .bx--list-box__menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  img {
    filter: brightness(100%) invert(1) brightness(100%);
    width: 20px;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const ModalInviteParticipant = ({ open, onClose, forum_type }: ItypeModal) => {

    const { setNotification, closeNotification } = useNotification()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const { profiles, setProfiles } = useProfile();
    const { setLoading } = useLoading();
    const {
        setParticipantList,
        participantList
    } = useForum()

    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {
            console.error(err);
            setLoading(false);
        }
    }, [setProfiles, setLoading]);

    const listProfiles = useMemo(() => {
        return profiles?.map((profile) => ({
            label: profile.name,
            value: String(profile.user.id),
        }));
    }, [profiles]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const toggleSelection = (item: string) => {
        setSelectedItems((prev) =>
            prev.includes(item)
                ? prev.filter((selectedItem) => selectedItem !== item)
                : [...prev, item]
        );
    };

    const fetchParticipantMandatoryForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeUserRole(Number(forum_type))

            setParticipantList(data.results)

        } catch { }
        finally {

            setLoading(false);
        }

    }, [participantList])

    const fetchSendUserRole = useCallback(async () => {

        setLoading(true);

        try {

            const promises = selectedItems?.map((user) => (

                postTypeUserRole({
                    forum_type: Number(forum_type),
                    type_vote: "nao_vota",
                    user: Number(user),
                    user_status: "convidado",
                })
            ));

            await Promise.all(promises);

            setNotification({
                status: "success",
                message: "Seu convite foi enviado com sucesso.",
                title: "Enviado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

        } catch (error) {

            setNotification({
                status: "error",
                message: "Não foi possível enviar seu convite, tente novamente mais tarde ou contate o administrador do sistema.",
                title: "Falha ao enviar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {
            fetchParticipantMandatoryForum()
            setLoading(false);
            onClose && onClose()
        }

    }, [forum_type, selectedItems]);


    useEffect(() => {
        const filteredResults = listProfiles?.filter((listItem) =>
            listItem.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredResults || []);
    }, [searchTerm, listProfiles]);


    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Convidar participante</h3>}
            open={open}
            height={"280px"}
            width="45%"
            onClose={() => {
                onClose && onClose();
            }}
            background="#ffffff"
            buttons={[
                {
                    label: "Cancel",
                    kind: "secondary",
                    onClick: () => {
                        onClose && onClose();
                    },
                },
                {
                    label: "Convidar participante",
                    kind: "primary",
                    onClick: () => fetchSendUserRole(),
                },
            ]}
        >
            <p className="w-[97%] mx-auto"></p>
            <ContainedList
                label="List title"
                kind="on-page"
                action={
                    <CustomSearch
                        placeholder="Digite para buscar um contato"
                        labelText="Search"
                        value={searchTerm}
                        onChange={handleChange}
                        closeButtonLabelText="Clear search input"
                        size="lg"
                    />
                }
            >
                {searchResults?.slice(0, 1).map((listItem, key) => (
                    <StyledContainedListItem
                        key={key}
                        selected={selectedItems?.includes(listItem.value)}
                        onClick={() => toggleSelection(listItem.value)}
                    >
                        <div className="flex items-center justify-start gap-[10px]">
                            <img src={userIcon} />
                            <p>{listItem.label}</p>
                        </div>
                    </StyledContainedListItem>
                ))}
            </ContainedList>
        </Modal>
    );
};
