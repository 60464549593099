import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getFilteredProfiles, getProfilesAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
// import { formatCPFCNPJ } from "~/services/utils";
import { permissions } from "~/services/permissions";
import editicon from "~/assets/editicon.png";
//import servicesicon from "~/assets/securityservices.png";
import renewicon from "~/assets/Renew.png";
import filterIcon from "~/assets/Filter.png";
import addicon from "~/assets/iconadd.png";
import closeIcon from "~/assets/close.png";
import {
  TableBasic,
  Breadcrumb,
  Tag,
  Dropdown,
  InputFluid,
  Button,
} from "prosperita-dumbo-react";
import { AddProfileModal } from "./AddProfileModal";
import { EditModalProfile } from "./EditModalProfile";
import Padding from "~/components/Padding";
import { useFormik } from "formik";
import { onlyNumbers } from "~/services/utils";
import useUser from "~/hooks/useUser";
import { EmptyRegister } from "./EmptyRegister";
import { AccessRestricted } from "../AccessRestricted";
// import { formatCPFCNPJ } from "~/services/utils";

const tableHeadersProfile: string[] = [
  "Nome",
  "Email",
  "Perfil",
  "Permissões",
  "Status",
  "",
  "",
];

type Idropdown = {
  value: string;
  label: string;
};

const options: Idropdown[] = [
  {
    value: "tool_manager",
    label: "Gestor de ferramenta",
  },
  {
    value: "area_manager",
    label: "Gestor de àrea",
  },
  {
    value: "internal",
    label: "Atendente",
  },
];
const canReadProfiles = permissions.includes("can_read_profiles");
// const canCreateProfiles = permissions.includes("can_create_profiles");
// const canEditProfiles = permissions.includes("can_edit_profiles")
// const canDeleteProfiles = permissions.includes("can_delete_profiles");

const clientTypeDisplay: { [key: string]: string } = {
  client: "Usuário comum (Empresa)",
  manager: "Usuário Master (Empresa)",
  internal: "Atendente",
  area_manager: "Gestor de àrea",
  tool_manager: "Gestor de ferramenta",
};

const Profiles = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const {
    setProfiles,
    profiles,
    setDetailsProfile,
    isProfileFormOpenModal,
    setIsProfileFormOpenModal,
    setEditModalProfile,
    editModalProfile,
  } = useProfile();

  const { userProfile } = useUser();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const fetchProfiles = useCallback(async () => {

    setLoading(true);

    try {

      const { data } = await getProfilesAPI({ mode: "list" });

      setProfiles(data.results.reverse());

      setLoading(false);

    } catch (err: unknown) {

      console.error(err);

    } finally {

      setLoading(false);
    }
  }, []);

  const { resetForm, handleChange, values } = useFormik({
    initialValues: {
      name: "",
      document_number: "",
      type: "",
      permission: "",
      period: "",
    },
    onSubmit: () => {
      ("");
    },
  });

  type IfilterPayload = {
    name: string;
    document_number: string;
    payment_company_approver: boolean;
    payment_approver: boolean;
    beneficiary_approver: boolean;
    period: string;
  };
  const payload: IfilterPayload = useMemo(() => {
    return {
      name: values.name,
      document_number: onlyNumbers(values.document_number),
      payment_company_approver:
        values.permission === "payment_company_approver"
          ? true
          : values.permission === "no_permission" && false,
      payment_approver:
        values.permission === "payment_approver"
          ? true
          : values.permission === "no_permission" && false,
      beneficiary_approver:
        values.permission === "beneficiary_approver"
          ? true
          : values.permission === "no_permission" && false,
      period: values.period,
    };
  }, [values]);

  const fetchProfilesFilter = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getFilteredProfiles(
        payload.name,
        payload.document_number,
        payload.period,
        payload.payment_company_approver,
        payload.payment_approver,
        payload.beneficiary_approver
      );
      setProfiles(data.results.reverse());
      setLoading(false);
      resetForm();
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [payload]);

  const dataProfile = useMemo(() => {
    return profiles.map((profile) => {
      const permissions = profile.beneficiary_approver
        ? "Aprovador de Beneficiários"
        : profile.payment_approver
          ? "Aprovador de Pagamentos"
          : profile.payment_company_approver
            ? "Aprovador de Pagamentos (Empresa)"
            : "Nenhuma";
      return {
        Nome: <div className="flex flex-col align-center">{profile.name}</div>,
        Email: profile.user.email,
        Grupo: clientTypeDisplay[profile.type[0]],
        Permissões: permissions,
        Status:
          <div className="w-[50px] text-center m-0 p-0">
            {profile.raw_data?.status ? (
              <Tag
                type={profile.raw_data.status === "active" ? "green" : "red"}
                position="center"
                width="100px"
              >
                <p className="text-[14px] m-0 p-[5px]">
                  {profile.raw_data.status === "active" ? "Ativo" : "Inativo"}
                </p>
              </Tag>
            ) : null}
          </div>,
        editar: (
          <button
            onClick={(e) => {
              e.stopPropagation();
              // setOpenDeleteProfileModal(true);
              setDetailsProfile(profile);
            }}
          >
            {/* {" "}
            <div className="m-0 p-0">
              <img src={servicesicon} alt="services" />
            </div> */}
          </button>
        ),
        edit: (
          <div className="w-[50px]">
            <Button
              kind="ghost"
              size="small"
              icon={editicon}
              onClick={(e) => {
                if (userProfile?.type.includes("tool_manager")) {
                  e.stopPropagation();
                  setEditModalProfile(true);
                  // setOpenEditProfileModal(true);
                  setDetailsProfile(profile);
                }
              }}
            />
          </div>
          // <button
          //   onClick={(e) => {
          //     if (userProfile?.type.includes("tool_manager")) {
          //       e.stopPropagation();
          //       setEditModalProfile(true);
          //       // setOpenEditProfileModal(true);
          //       setDetailsProfile(profile);
          //     }
          //   }}
          // >
          //   {" "}
          //   <div className="m-0 p-0">
          //     <img style={{ objectFit: "contain" }} src={editicon} alt="edit" />
          //   </div>
          // </button>
        ),
      };
    });
  }, [profiles]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);



  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div className="w-full mx-auto">
        <div className="bg-[#f4f4f4] h-[170px] m-0 w-full">
          <div className="flex flex-col">
            <div className="mt-[50px] ml-[50px] w-1/2 flex flex-col items-start text-left">
              <Breadcrumb
                crumbsOptions={[
                  { crumb: "Configurações", onClick: () => navigate("/app") },
                  { crumb: "Usuários" },
                ]}
                endHiddenIndex={5}
                startHiddenIndex={5}
              />
            </div>
            <h2 className="mt-4 ml-[45px] text-black text-[40px] font-light leading-[40px]">
              Usuários
            </h2>
          </div>
        </div>
      </div>
      <Margin mb={70} />
      <>
        {userProfile?.type.includes("tool_manager") || userProfile?.type.includes("area_manager") ? (
          <>
            {profiles.length > 0 ? (
              <>
                {isFilterOpen && (
                  <div
                    style={{ borderRadius: "8px 8px 0px 0" }}
                    className="bg-[#f4f4f4] w-[95%] mx-auto m-0 p-0"
                  >
                    <div className="d-flex justify-between py-3 w-[97%] mx-auto">
                      <h3 className="m-0 p-0">Filtros</h3>
                      <div className="flex pt-1 w-[300px] justify-around box-border ">
                        <div className="flex w-[100%]">
                          <Dropdown
                            kind="default"
                            options={[
                              {
                                label: "Ultimos 6 Meses",
                                value: "180",
                              },
                              {
                                label: "Ultimos 3 Meses",
                                value: "90",
                              },
                              {
                                label: "Ultimos 1 Mês",
                                value: "30",
                              },
                            ]}
                            size="large"
                            name="period"
                            onChange={handleChange}
                            placeholder="Ultimos 6 Meses"
                            borderNone
                            noRadius
                          />
                        </div>
                        <div className="w-[10%] box-border align-end">
                          <Button
                            kind="ghost"
                            size="small"
                            borderNone
                            icon={closeIcon}
                            width="100%"
                            onClick={() => setIsFilterOpen(false)}
                          />
                        </div>
                      </div>
                    </div>
                    <Margin mt={20} />
                    <div
                      style={{ borderBottom: "1px solid #8D8D8D" }}
                      className="flex m-0 p-0 align-center gap-[10px] mx-auto w-[97%]"
                    >
                      <InputFluid
                        label="Nome"
                        message=""
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder="Digite o Nome"
                        noRadius
                        borderNone
                      />
                      <InputFluid
                        label="CNPJ"
                        message=""
                        type="text"
                        onChange={handleChange}
                        name="document_number"
                        value={values.document_number}
                        placeholder="Digite o CNPJ do befeficiário"
                        noRadius
                        borderNone
                      />
                      <Dropdown
                        kind="fluid"
                        label="Tipo de Perfil"
                        options={options}
                        onChange={handleChange}
                        size="large"
                        name="permission"
                        placeholder="Selecione Permissoes"
                        borderNone
                        noRadius
                      />
                      <Dropdown
                        kind="fluid"
                        onChange={handleChange}
                        label="Tipo de Permissoes"
                        options={[
                          {
                            label: "Aprovador(a) de Pagamentos",
                            value: "payment_approver",
                          },
                          {
                            label: "Aprovador(a) de Beneficiários",
                            value: "beneficiary_approver",
                          },
                          {
                            label: "Aprovador(a) de Empresas",
                            value: "payment_company_approver",
                          },
                          {
                            label: "Nenhuma Permissão",
                            value: "no_permission",
                          },
                        ]}
                        size="medium"
                        name="permission"
                        placeholder="Selecione Permissoes"
                        borderNone
                        noRadius
                      />
                    </div>
                    <Margin mt={30} />
                    <div className="flex justify-end w-[97%] mx-auto align-center">
                      <Button
                        label="Aplicar filtros"
                        kind="tertiary"
                        size="large"
                        icon={filterIcon}
                        onClick={() => fetchProfilesFilter()}
                      />
                    </div>
                    <Padding pb={30} />
                  </div>
                )}
                <div className="w-[95%] mx-auto">
                  {canReadProfiles && (
                    <TableBasic
                      search
                      buttons={[
                        {
                          kind: "ghost",
                          label: "",
                          dimension: "37",
                          width: "37px",
                          onClick: () => fetchProfiles(),
                          position: "right",
                          icon: renewicon,
                        },
                        {
                          kind: "ghost",
                          label: "",
                          dimension: "37",
                          width: "37px",
                          onClick: () => setIsFilterOpen((prev) => !prev),
                          position: "right",
                          icon: filterIcon,
                        },
                        {
                          kind: "primary",
                          label: "Novo Usuario",
                          dimension: "",
                          onClick: () => setIsProfileFormOpenModal(true),
                          position: "right",
                          icon: addicon,
                        },
                      ]}
                      pageSizes={[10, 5, 20, 30, 40, 50]}
                      rowData={dataProfile}
                      columnTitles={tableHeadersProfile}
                    />
                  )}
                </div>
                <Margin mb={50} />
              </>
            ) : (
              <EmptyRegister
                openModal={() => setIsProfileFormOpenModal(true)}
              />
            )}
          </>
        ) : (
          <>
            <AccessRestricted
              text="Você não tem permissão para ver o painel de usuários, 
             contate o administrador do sistema para mais informações."
            />
          </>
        )}
      </>
      <EditModalProfile
        open={editModalProfile}
        onClose={() => setEditModalProfile(false)}
      />
      <AddProfileModal
        open={isProfileFormOpenModal}
        onClose={() => setIsProfileFormOpenModal(false)}
      />
    </div>
  );
};

export default Profiles;
