import { Breadcrumb, Button, Dropdown, InputFluid, TableExpandable, Tag } from "prosperita-dumbo-react"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom";
import filterIcon from "~/assets/Filter.png"
import viewIcon from "~/assets/viewIcon.png"
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import { getForumAPI, getForumFilterAPI, getTypeForum, getTypeUsersRoles } from "~/services/api/forum";
import { addHoursToDate, formatarDataISO } from "~/services/utils";
import closeIcon from "~/assets/close.png";
import emptyState from "~/assets/EmptyState.png";
import { useFormik } from "formik";

const columnTitles: ReactNode[] = [
    "ID",
    "Tipo",
    "Nome",
    "Criação",
    "Limite da votação",
    "Status",
    ""
]
type ItypeFilter = {
    label: string,
    value: string,
    type: "blue" | "cyan" | "gray" | "green" | "magenta" | "red" | "teal" | "skeleton" | "focus" | "inverse" | "border"
}
const status_filter: ItypeFilter[] = [
    {
        label: "Todos",
        type: "border",
        value: ""
    },
    {
        label: "Aberto",
        type: "gray",
        value: "aberto"
    },
    {
        label: "Vetado",
        type: "gray",
        value: "vetado"
    },
    {
        label: "Aprovado",
        type: "gray",
        value: "aprovado"
    },
    {
        label: "Encerrado",
        type: "gray",
        value: "encerrado"
    }
]
export const ListForum = () => {

    const navigate = useNavigate()

    const [filterOn, setFilterOn] = useState<boolean>(false);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const [statusFilter, setStatusFilter] = useState<ItypeFilter[]>(status_filter);

    const { values, setFieldValue, resetForm } = useFormik({

        initialValues: {
            forum_type: 0,
            forum_status: ""
        },
        onSubmit: () => { }
    })

    const handleTagClick = (index: number, value: string) => {

        setFieldValue("forum_status", value)

        setStatusFilter((prevFilters) =>
            prevFilters.map((filter, i) => ({
                ...filter,
                type: i === index ? "blue" : "gray",
            }))
        );
    };

    const { setLoading } = useLoading()

    const {
        setListForum,
        listForum,
        setParticipantList,
        participantList,
        setlistTypeForum,
        listTypeForum
    } = useForum()

    const fecthTypeForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeForum()

            setlistTypeForum(data.results)

        } catch {

        } finally {

            setLoading(false);
        }
    }, [listTypeForum])

    const fetchForumAPI = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await getForumAPI()

            setListForum(data.results)

        } catch {

        } finally {

            setLoading(false)
        }
    }, [listForum])

    const fetchForumFilteredAPI = useCallback(async () => {

        setLoading(true)

        const payload = {
            forum_status: values.forum_status,
            forum_type: Number(values.forum_type)
        }

        try {
            const { data } = await getForumFilterAPI(
                payload.forum_type,
                payload.forum_status
            )

            setListForum(data.results)

            setFilterOn(true)

        } catch (error) {

            console.warn(error)

        } finally {

            setLoading(false)
        }
    }, [listForum, values])


    const fetchParticipantsForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeUsersRoles()

            setParticipantList(data.results)

        } catch (error) {

            console.error(error);

        } finally {

            setLoading(false);
        }

    }, [participantList]);


    const renderForum = useMemo(() => {

        const status_name: { [key: string]: JSX.Element } = {

            aberto:
                <div className="w-[100px]">
                    <Tag position="center" type="blue">
                        <p className="p-[5px] m-0">Aberto</p>
                    </Tag>
                </div>,
            pendente_de_votacao:
                <div>
                    <Tag position="center" type="border">
                        <p className="p-1 m-0">Pendente de votação</p>
                    </Tag>
                </div>,
            encerrado:
                <div className="w-[110px]">
                    <Tag position="center" type="inverse">
                        <p className="p-[5px] m-0">Encerrado</p>
                    </Tag>
                </div>,
            vetado:
                <div className="w-[110px]">
                    <Tag position="center" type="red">
                        <p className="p-[5px] m-0">Vetado</p>
                    </Tag>
                </div>,
        }

        return listForum?.map((forum) => ({
            "ID": <p className="p-0 m-0">000{forum?.id}</p>,
            "Tipo": forum?.forum_type?.name,
            "Nome": forum?.name,
            "Criação": formatarDataISO(String(forum?.created_on)),
            "Limite da votação": addHoursToDate(String(forum?.vote_deadline), String(forum?.created_on)),
            "Status": status_name[forum?.forum_status],
            "view":
                <div className="w-[40px]">
                    <Button
                        kind="ghost"
                        size="small"
                        icon={viewIcon}
                        label=""
                        onClick={() => navigate("/app/forum/activities/", { state: { id: forum?.id } })}
                        noPadding="3px"
                    />
                </div>,
            "N. de participantes : ": participantList?.filter((users) => String(users?.forum_type) === String(forum?.forum_type.id))?.length
        }))
    }, [listForum, participantList])


    const dropListTypeForum = useMemo(() => {
        return listTypeForum?.map((typeforum) => ({
            label: String(typeforum.name),
            value: String(typeforum.id)
        }));
    }, [listTypeForum]);


    useEffect(() => {
        fetchParticipantsForum()
        fetchForumAPI()
        fecthTypeForum()
    }, [])

    return (
        <div>
            <div className="w-full mx-auto">
                <div>
                    <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                        <div className="flex flex-col">
                            <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                                <Breadcrumb
                                    crumbsOptions={[
                                        { crumb: "Painel", onClick: () => "" },
                                        { crumb: "Fórum" },
                                    ]}
                                    endHiddenIndex={5}
                                    startHiddenIndex={5}
                                />
                            </div>
                            <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                                Meus fóruns
                            </h2>
                        </div>
                    </div>
                </div>
                {listForum && (listForum.length > 0 || filterOn) ?
                    <div>
                        <Margin mt={50} />
                        {isFilterOpen && (
                            <div
                                style={{ borderRadius: "8px 8px 0px 0" }}
                                className="bg-[#f4f4f4] w-[95%] mx-auto m-0 p-0"
                            >
                                <div className="d-flex justify-between py-3 w-[97%] mx-auto">
                                    <h3 className="m-0 p-0">Filtros</h3>
                                    <div className="flex pt-1 w-[300px] justify-around box-border ">
                                        <div className="flex w-[100%]">
                                            <Dropdown
                                                kind="default"
                                                options={[
                                                    {
                                                        label: "Ultimos 6 Meses",
                                                        value: "180",
                                                    },
                                                    {
                                                        label: "Ultimos 3 Meses",
                                                        value: "90",
                                                    },
                                                    {
                                                        label: "Ultimos 1 Mês",
                                                        value: "30",
                                                    },
                                                ]}
                                                size="large"
                                                name="period"
                                                placeholder="Ultimos 6 Meses"
                                                borderNone
                                                noRadius
                                            />
                                        </div>
                                        <div className="w-[10%] box-border align-end">
                                            <Button
                                                kind="ghost"
                                                size="small"
                                                borderNone
                                                icon={closeIcon}
                                                width="100%"
                                                onClick={() => setIsFilterOpen(false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Margin mt={20} />
                                <div className="w-[98%] flex gap-[5px] mx-auto mb-[20px]" style={{ borderBottom: "1px solid #8D8D8D" }}>
                                    <Dropdown
                                        kind="fluid"
                                        size="large"
                                        options={dropListTypeForum || []}
                                        label="Tipo de fórum"
                                        message=""
                                        type="text"
                                        name="name"
                                        placeholder="Escolha o tipo do fórum"
                                        onChange={(event) => setFieldValue("forum_type", event.target.value)}
                                        noRadius
                                        borderNone
                                    />
                                </div>
                                <div className="w-[98%] flex mx-auto gap-[5px]" style={{ borderBottom: "1px solid #8D8D8D" }}>
                                    <section className="flex flex-col w-[100%]">
                                        <p className="p-0 my-[10px] font-[400]"><b>Datas de criação</b></p>
                                        <div className="w-[100%] flex gap-[5px]">
                                            <InputFluid
                                                label="De"
                                                message=""
                                                type="date"
                                                name="document_number"
                                                placeholder="Digite o CNPJ do befeficiário"
                                                noRadius
                                                borderNone
                                            />
                                            <InputFluid
                                                label="Ate"
                                                message=""
                                                type="date"
                                                name="document_number"
                                                placeholder="Digite o CNPJ do befeficiário"
                                                noRadius
                                                borderNone
                                            />
                                        </div>
                                    </section>
                                    <section className="flex flex-col w-[100%]">
                                        <p className="p-0 my-[10px] font-[400]"><b>Data de encerramento</b></p>
                                        <div className="w-[100%] flex gap-[5px]">
                                            <InputFluid
                                                label="De"
                                                message=""
                                                type="date"
                                                name="document_number"
                                                placeholder="Digite o CNPJ do befeficiário"
                                                noRadius
                                                borderNone
                                            />
                                            <InputFluid
                                                label="Ate"
                                                message=""
                                                type="date"
                                                name="document_number"
                                                placeholder="Digite o CNPJ do befeficiário"
                                                noRadius
                                                borderNone
                                            />
                                        </div>
                                    </section>
                                </div>
                                <Margin mt={30} />
                                <div className="flex justify-between w-[97%] mx-auto align-center items-center">
                                    <div className="m-0 p-0">
                                        <p className="m-0 p-0"><b className="m-0 p-0">Status</b></p>
                                        <Margin mt={20} />
                                        <div className="flex gap-[5px] m-0 p-0" >
                                            {statusFilter?.map((filter, index) => (
                                                <div className="m-0 p-0" >
                                                    <Tag position="center" type={filter?.type}>
                                                        <p className="m-0 p-2"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleTagClick(index, filter.value)}>{filter?.label}</p>
                                                    </Tag>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <Button
                                        label="Aplicar filtros"
                                        kind="tertiary"
                                        size="large"
                                        icon={filterIcon}
                                        onClick={() => fetchForumFilteredAPI()}
                                    />
                                </div>
                                <Padding pb={30} />
                            </div>
                        )}
                        <div className="w-[95%] mx-auto">
                            <TableExpandable
                                search
                                columnTitles={columnTitles}
                                pageSizes={[15, 20, 30, 50]}
                                label="Listagem de fóruns"
                                buttons={[
                                    {
                                        size: "small",
                                        label: "",
                                        kind: "ghost",
                                        position: "right",
                                        dimension: "37",
                                        icon: filterIcon,
                                        width: "40px",
                                        onClick: () => {
                                            setIsFilterOpen((prev) => !prev)
                                            resetForm && resetForm()
                                        }
                                    },
                                    {
                                        size: "small",
                                        label: "Criar novo fórum",
                                        kind: "primary",
                                        position: "right",
                                        dimension: "37",
                                        onClick: () => navigate("/app/forum/creat-forum/")
                                    }
                                ]}

                                columsKeys={[
                                    "ID",
                                    "Tipo",
                                    "Nome",
                                    "Criação",
                                    "Limite da votação",
                                    "Status",
                                    "view",
                                ]}

                                expandedRowData={["N. de participantes : "]}

                                rowData={renderForum || []}
                            />
                        </div>
                    </div>
                    :
                    <div className="w-[95%] mx-auto">
                        <div className="flex justify-between w-[100%] items-start mt-[50px]">
                            <img src={emptyState} className="w-[200px]" />
                            <Button
                                kind="primary"
                                size="large"
                                label="Criar novo fórum"
                                onClick={() => navigate("/app/forum/creat-forum/")}
                            />
                        </div>
                        <div className="w-[100%] mt-[50px]">
                            <Margin mt={20} />
                            <h5>Não há nenhum fórum criado</h5>
                            <Margin mt={20} />
                            <p>Não há fóruns para serem exibidos no momento, para criar um novo fórum clique no botão direito localizado no canto superior.</p>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
