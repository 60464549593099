import { Section } from "@carbon/react";
import "@fontsource/ibm-plex-sans";
import { Logout } from "@carbon/icons-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import { Outlet, useLocation, useNavigate } from "react-router-dom"; //useLocation
import useAuth from "~/hooks/useAuth";
import useUser from "~/hooks/useUser";
import { useCallback, useEffect, useState } from "react";
import BanvoxLogo from "~/assets/banvox.png";
import { getProfilesAPI } from "~/services/api/profile";
import AppStorage from "~/services/storage";
import { getRefreshTokenAPI } from "~/services/api/auth";
import FirstLoginModal from "./FirstLoginModal";
import { UIHeaderNav, Tooltip } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import useForum from "~/hooks/useForum";
import AuthenticateFund from "./Funds/AuthenticateFund/AuthenticateFund";

const DashboardContainer = styled.div`
  min-height: 100vh;

  .cds--header--navigation {
    top: 48px;

    nav {
      padding-left: 0;
    }
  }
  .header {
    z-index: 1;
  }
`;

const DashboardStructure = () => {
  const navigate = useNavigate();

  const {
    setUserProfile,
    userProfile,
    setPass,
    setIsFirstLogin,
    isFirstLogin,
  } = useUser();
  const { setParticipantMandatory } = useForum();
  const { logout } = useAuth();

  const { pathname } = useLocation();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  // const canReadTasks = permissions.includes("can_read_tasks");

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", { hour: "2-digit", minute: "2-digit" })
  );

  const fetchRefreshToken = async () => {
    const refresh = AppStorage.getRefresh();
    const payload = { refresh: refresh };

    try {
      const { data } = await getRefreshTokenAPI(payload);
      AppStorage.setToken(data.access);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI();
      setUserProfile(data.results[0]);
    } catch (e) {
      console.error(e);
      handleLogout();
    }
  }, []);

  useEffect(() => {
    fetchRefreshToken();
    fetchUserProfile();
    setInterval(() => {
      fetchRefreshToken();
    }, 120000);
  }, []);

  const excludedPaths = [
    // "/app/forum/forum-type/",
    "/app/forum/summary-forum/",
    "/app/forum/new-type-forum/",
  ];

  useEffect(() => {
    if (!excludedPaths.includes(pathname)) {
      AppStorage.remove("@participant_mandatory");
      AppStorage.remove("@form_type_forum");
      setParticipantMandatory([]);
    }
  }, [pathname]);


  useEffect(() => {
    if (userProfile?.raw_data?.first_login) {
      setIsFirstLogin(true);
    } else {
      setIsFirstLogin(false);
    }
  }, [userProfile, setIsFirstLogin, setPass]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000); // Atualiza a cada segundo
    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userProfile?.raw_data?.first_login === false) {
        AppStorage.remove("@app:pass");
      }
    }, 20000);

    return () => clearTimeout(timer);
  }, [userProfile]);

  return (
    <>
      {pathname.includes("app/autheticate-fund") ? (
        <AuthenticateFund />
      ) : (
        <div>
          {" "}
          <UIHeaderNav
            navigateProfile={() => {
              navigate("my-data");
            }}
            SectionRightTime={
              <p
                style={{
                  margin: "0",
                  padding: "0",
                }}
              >
                {new Date().toLocaleDateString()}- {currentTime}
              </p>
            }
            options={[
              {
                label: "Atendimentos",
                permission: true,
                onClick: () => navigate("attends/"),
              },
              {
                label: "Fórum",
                permission: true,
                optionsMenu: [
                  {
                    label: "Tipo de Fóruns",
                    onClick: () => navigate("/app/forum/forum-type/"),
                    permission: true,
                  },
                  {
                    label: "Meus fóruns",
                    onClick: () => navigate("/app/forum/list-forum/"),
                    permission: true,
                  },
                ],
              },
              {
                label: "Pagamentos",
                onClick: () => navigate("payments/"),
                permission: true,
              },
              {
                label: "Beneficiários",
                permission: true,
                onClick: () => navigate("/app/beneficiaries"),
              },

              {
                label: "Empresa",
                permission:
                  userProfile?.type.includes("manager") ||
                  userProfile?.type.includes("client"),
                onClick: () => navigate("/app/company-data"),
              },

              {
                label: "Configurações",
                permission:
                  userProfile?.type.includes("tool_manager") ||
                  userProfile?.type.includes("area_manager"),
                optionsMenu: [
                  {
                    label: "Usuários",
                    permission: true,
                    onClick: () => navigate("/app/profiles"),
                  },
                  {
                    label: "Empresas",
                    permission: true,
                    onClick: () => navigate("/app/companys"),
                  },
                  {
                    label: "Fundos",
                    permission: true,
                    onClick: () => navigate("/app/funds"),
                  },
                  {
                    label: "Áreas",
                    permission: true,
                    onClick: () => navigate("/app/areas"),
                  },
                  {
                    label: "Status de Atendimento",
                    permission: true,
                    onClick: () => navigate("/app/status"),
                  },
                  {
                    label: "Tipos de Atendimento",
                    permission: true,
                    onClick: () => navigate("/app/attend-types"),
                  },
                  {
                    label: "Tipos de despesas",
                    permission: true,
                    onClick: () => navigate("/app/payments-types"),
                  },
                ],
              },
            ]}
            icon={
              <>
                <img
                  onClick={() => navigate("/")}
                  src={BanvoxLogo}
                  alt=""
                  style={{
                    height: "60px",
                    cursor: "pointer",
                    filter: "brightness(10%) invert(1) brightness(100%)",
                  }}
                />
              </>
            }
            userName={
              <>
                Olá,{" "}
                {userProfile?.user.first_name === ""
                  ? "Torrano"
                  : userProfile?.user.first_name}
              </>
            }
            emailuser={`${userProfile?.user.email}`}
            bgHeader="#0A4A87"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "60px",
                justifyContent: "center",
                width: "50px",
                padding: "0",
                margin: "0 10px 0 0",
              }}
            >
              {" "}
              <Tooltip align="bottom" size="small" label="Sair" width="60px">
                <div
                  style={{
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleLogout}
                >
                  <Logout />
                </div>
              </Tooltip>
            </div>
          </UIHeaderNav>
          <Margin mt={100} />
          <DashboardContainer>
            <Section>
              <Outlet />
            </Section>{" "}
            <Margin mt={90} />

            <FirstLoginModal onClose={() => null} open={isFirstLogin} />
          </DashboardContainer>
        </div>
      )}
    </>
  );
};

export default DashboardStructure;
