import { Breadcrumb, Button } from "prosperita-dumbo-react";
import ProgressIndicator from "~/components/ProgressIndicator";
import { TypeForum } from "./TypeForum";
import { Summary } from "./Summary";
import { SettingsParticipants } from "./SettingsParticipants";
import { useState } from "react";
import { SettingsForum } from "./SettingsForum";
import { FormikErrors, FormikHandlers, FormikState, useFormik } from "formik";
import { ModalCreateForum } from "./ModalCreateForum";
import useForum from "~/hooks/useForum";
import { useNavigate } from "react-router-dom";

type IfileType = {
    lastModified: number,
    lastModifiedDate: unknown,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string
}

type Values = {
    type_forum: string,
    title_forum: string,
    deadline: string,
    forum_description: string,
    file: IfileType[]
    guests: string
    vote_deadline: string,
    fund: string
}

const initialValues = {
    type_forum: "",
    title_forum: "",
    deadline: "",
    forum_description: "",
    file: [],
    guests: "",
    vote_deadline: "",
    fund: ""
}

export type ItypeValidate = {
    onValidate: React.Dispatch<React.SetStateAction<boolean>>,
    formik: {
        values: Values;
        handleChange: FormikHandlers['handleChange'];
        setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) =>
            Promise<void> | Promise<FormikErrors<Values>>,
        resetForm?: (nextState?: Partial<FormikState<Values>> | undefined) => void
    }
}

export const CreatForumGeneral = () => {

    const [currentStep, setCurrentStep] = useState(1);

    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);

    const navigate = useNavigate()

    const {
        setmodalCreatForum,
        modalCreatForum
    } = useForum()

    const { handleChange, values, setFieldValue } = useFormik(({
        initialValues,
        onSubmit: () => { }
    }))

    const [steps, setSteps] = useState([
        {
            label: "Tipos de fórum",
            current: currentStep === 1,
            completed: false
        },
        {
            label: "Configurações de fórum",
            current: currentStep === 2,
            completed: false
        },
        {
            label: "Configurações dos participantes",
            current: currentStep === 3,
            completed: false
        },
        {
            label: "Resumo",
            current: currentStep === 4,
            completed: false
        },
    ]);

    const renderForumForm = [
        {
            content:
                <Summary
                    onValidate={setIsCurrentStepValid}
                    formik={{ handleChange, values, setFieldValue }}
                />,
            render: currentStep === 4
        },
        {
            content:
                <TypeForum
                    onValidate={setIsCurrentStepValid}
                    formik={{ handleChange, values, setFieldValue }}
                />,
            render: currentStep === 1
        },
        {
            content:
                <SettingsForum
                    onValidate={setIsCurrentStepValid}
                    formik={{ handleChange, values, setFieldValue }}
                />,
            render: currentStep === 2
        },
        {
            content:
                <SettingsParticipants
                    onValidate={setIsCurrentStepValid}
                    formik={{ handleChange, values, setFieldValue }}
                />,
            render: currentStep === 3
        }
    ]

    const handleNextStep = () => {

        if (currentStep < steps.length) {

            const updatedSteps = steps.map((step, index) => {

                if (index === currentStep - 1) {
                    return { ...step, completed: true, current: false };
                }
                if (index === currentStep) {
                    return { ...step, current: true };
                }
                return step;
            });

            setSteps(updatedSteps);

            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Configurações", onClick: () => "" },
                                    { crumb: "Fórum" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            Novo fórum
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[97%] mt-[50px] mx-auto">
                <ProgressIndicator steps={steps} />
                {renderForumForm.map((form, index) => (
                    <div key={index} className="mt-[30px]">
                        {form.render && form.content}
                    </div>
                ))}
                <div className="flex justify-between my-[100px]">
                    <Button
                        label="Cancelar"
                        kind="ghost"
                        size="large"
                        onClick={() => navigate("/app/forum/list-forum/")}
                    />
                    <Button
                        label="Avançar"
                        kind="primary"
                        size="large"
                        disabled={!isCurrentStepValid}
                        onClick={() =>
                            currentStep === 4 ?
                                setmodalCreatForum(true) :
                                handleNextStep()}
                    />
                </div>
            </div>
            <ModalCreateForum
                values={values}
                open={modalCreatForum}
                onClose={() => setmodalCreatForum(false)} />
        </div>
    );
};
