import { IAttend, IHistory } from "~/types";
import ApiService from "../api";

type IResponseAttend = {
  results: IAttend[];
};

const expand = "status,type,fund,client,owner,area";
const expand_id = "status,type,fund,client,owner,area";

export const getAttendAPI = () => {
  return ApiService.HttpGet<IResponseAttend>({
    route: "attend/attend/",
    token: true,
    params: {
      expand,
    },
  });
};

export const getAttendDetailsAPI = (id: number) => {
  return ApiService.HttpGet<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    params: {
      expand: expand_id,
      origin: "payment",
    },
  });
};

export const getAttendDetailsforumAPI = (id: number) => {
  return ApiService.HttpGet<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    params: {
      expand: expand_id,
    },
  });
};

export const getAttendHistoryAPI = (id: number) => {
  return ApiService.HttpGet<IHistory[]>({
    route: `history/`,
    token: true,
    params: {
      attend: id,
    },
  });
};

export type ISendUpdatePatchBody = {
  status?: number;
  owner?: number | null;
  type?: number;
  in_client_revision?: boolean;
};

export const patchAttendDetailsAPI = (
  id: number,
  body: ISendUpdatePatchBody
) => {
  return ApiService.HttpPatch<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    body: body,
  });
};

export type ISendNewAttendBody = {
  message: string;
  type?: number;
  fund: number | null;
  client: number;
  owner?: number;
  area?: number;
  parent?: number;
};

export const postAttendAPI = (data: ISendNewAttendBody) => {
  return ApiService.HttpPost<IAttend>({
    route: "attend/attend/",
    body: data,
    token: true,
  });
};

export const deleteAttendDetailsAPI = (id: number) => {
  return ApiService.HttpDelete<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
  });
};
