import { Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import useUser from "~/hooks/useUser";
import { postAttendAPI } from "~/services/api/attend";
import { postAttendTaskFileAPI } from "~/services/api/attendTaskFile";
import { postCreateForum, postForumTopicAPI } from "~/services/api/forum";

type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum_type?: string,
    values: Values
}
type IfileType = {
    lastModified: number,
    lastModifiedDate: unknown,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string
}
type Values = {
    type_forum: string,
    title_forum: string,
    deadline: string,
    forum_description: string,
    file: IfileType[]
    guests: string
    vote_deadline: string,
    fund: string
}

export const ModalCreateForum = ({ open, onClose, values }: ItypeModal) => {

    const { setNotification, closeNotification } = useNotification()

    const { setLoading } = useLoading()

    const navigate = useNavigate()

    console.log(values?.file)
    const {
        userProfile,
    } = useUser();

    const sendCreatForum = useCallback(async () => {

        // const formData = new FormData();

        // values?.file?.forEach((file) => {

        //     if (file instanceof File) {

        //         formData.append('file', file);
        //     }
        // });

        // formData.append("sender", String(userProfile?.id || ""));
        // formData.append("description", `${values.forum_description}`);

        setLoading(true)

        try {

            const { data } = await postAttendAPI({
                client: userProfile?.id || 0,
                fund: values.fund ? Number(values.fund) : null,
                message: values.forum_description,
                type: 49,
                area: 46
            })

            const { data: forum } = await postCreateForum({
                attend: String(data.id),
                description: values.forum_description,
                forum_type: values.type_forum,
                name: values.title_forum,
                vote_deadline: values.vote_deadline,
                forum_status: "aberto",
            })

            await postForumTopicAPI({
                content: values.forum_description,
                forum: Number(forum.id),
                title: values.title_forum,
                user: userProfile?.id || 0
            })

            if (values?.file.length > 0) {

                //const { data: id_file } = await postAttendTaskFileAPI(formData);

                const uploadPromises = values?.file?.map((file) => {

                    const formData = new FormData();
                    if (file instanceof File) {
                        formData.append("file", file);
                    }
                    // formData.append("sender", String(userProfile?.id || ""));
                    formData.append("attend", String(data.id));
                    formData.append("sender", String(userProfile?.id || ""));
                    formData.append("description", `${values.forum_description}`);

                    return postAttendTaskFileAPI(formData);
                });

                // await postAttendTaskFileAPI(formData);

                await Promise.all(uploadPromises);

                // await postForumAttachment({
                //     file: id_file.id,
                //     forum: Number(forum.id),
                //     forum_type: values.type_forum,
                //     topic: topic.id
                // })
            }

            setNotification({
                status: "success",
                message: "O fórum foi criado com sucesso.",
                title: "Fórum criado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            navigate("/app/forum/list-forum/")

        } catch {

            setNotification({
                status: "error",
                message: "Não foi possível criar o fórum no momento, tente novamente mais tarde ou contate o administrador do sistema",
                title: "Falha ao criar fórum",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false)
            onClose && onClose()
        }
    }, [values, userProfile])


    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Criar novo fórum</h3>}
            open={open}
            height={"280px"}
            width="45%"
            onClose={() => {
                onClose && onClose();
            }}
            background="#ffffff"
            buttons={[
                {
                    label: "Não, manter como está",
                    kind: "secondary",
                    onClick: () => {
                        onClose && onClose();
                    },
                },
                {
                    label: "Sim, criar novo fórum",
                    kind: "dangerPrimary",
                    onClick: () => sendCreatForum(),
                },
            ]}
        >
            <p className="w-[97%] mx-auto">Tem certeza que gostaria de criar este fórum? </p>
        </Modal>
    );
};
