import { ItypeModal } from "prosperita-dumbo-react/dist/types/Components/Modal";
import { Modal } from "prosperita-dumbo-react";

export const SendTypeCreatForum = ({ open, onClose }: ItypeModal) => {
    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Criar novo tipo de fórum</h3>}
            open={open}
            height={"350px"}
            width="45%"
            onClose={() => {
                onClose && onClose()
            }}
            background="#f4f4f4"
            buttons={
                [
                    {
                        label: "Não, manter como está",
                        kind: "secondary",
                        onClick: () => {
                            onClose && onClose()
                        }
                    },
                    {
                        label: "Sim, enviar para aprovação",
                        kind: "dangerPrimary",
                        onClick: () => "",
                    }
                ]
            }
        >
            <p className="w-[97%] mx-auto">
                Tem certeza que gostaria de criar este tipo de fórum? </p>
        </Modal>
    );
};