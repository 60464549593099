import { Modal } from "prosperita-dumbo-react";
import { useCallback, useEffect } from "react";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import { deleteTypeForumAPI, deleteTypeUserRoleAPI, getTypeForum, getTypeUserRole } from "~/services/api/forum";

type ItypeModal = {
    open: boolean,
    onClose: () => void
}

export const DeleteTypeForum = ({ open, onClose }: ItypeModal) => {


    const { setLoading } = useLoading();

    const { setNotification, closeNotification } = useNotification()

    const {
        deleteTypeForumdata,
        setlistTypeForum,
        listTypeForum,
        setParticipantList,
        participantList
    } = useForum()

    const fecthTypeForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeForum()

            setlistTypeForum(data.results)

        } catch {

        } finally {

            setLoading(false);
        }
    }, [listTypeForum])

    const fetchParticipantMandatoryForum = useCallback(async () => {

        setLoading(true);

        try {

            if (deleteTypeForumdata) {

                const { data } = await getTypeUserRole(deleteTypeForumdata?.id)

                console.log(data.results)

                setParticipantList(data.results)
            }
        } catch {

        } finally {

            setLoading(false);
        }
    }, [participantList, deleteTypeForumdata])


    const fetchdeleteTypeForum = useCallback(async () => {

        setLoading(true);

        try {
            if (deleteTypeForumdata) {
                await deleteTypeForumAPI(deleteTypeForumdata.id)
            }

            if (participantList) {
                const promiseList = participantList?.map((users) => (
                    deleteTypeUserRoleAPI(users.id)
                ))
                await Promise.all(promiseList)
            }

            setNotification({
                status: "success",
                message: "O tipo de fórum foi excluido com sucesso.",
                title: "Excluido com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            onClose && onClose()

        } catch {
            setNotification({
                status: "error",
                message: "Não foi possível excluir o tipo de fórum no momento, tente novamente mais tarde ou contate o administrador do sistema",
                title: "Falha ao excluir",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {
            setLoading(false);
            fecthTypeForum()
        }
    }, [deleteTypeForumdata, participantList])


    useEffect(() => {
        fetchParticipantMandatoryForum()
    }, [deleteTypeForumdata])

    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Excluir tipo de fórum</h3>}
            open={open}
            height={"350px"}
            width="45%"
            onClose={() => {
                onClose && onClose()
            }}
            background="#f4f4f4"
            buttons={
                [
                    {
                        label: "Não, manter como está",
                        kind: "secondary",
                        onClick: () => {
                            onClose && onClose()
                        }
                    },
                    {
                        label: "Sim, excluir tipo de fórum",
                        kind: "dangerPrimary",
                        onClick: () => fetchdeleteTypeForum(),
                    }
                ]
            }
        >
            {/* <div className="h-[2px] bg-[#c6c6c6] w-[100%] mt-[30px] mb-0"></div> */}
            <p className="w-[97%] mx-auto">
                Tem certeza que gostaria de remover este tipo de fórum?
                Esta ação não poderá ser revertida</p>
        </Modal>
    );
};