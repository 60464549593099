import { Breadcrumb, Button, Margin, TableBasic } from "prosperita-dumbo-react"
import { useLocation, useNavigate } from "react-router-dom"
import { SendTypeCreatForum } from "./SendTypeCreatForum"
import useForum from "~/hooks/useForum"
import { ModalEditTypeForum } from "./ModalEditTypeForum"
import AppStorage from "~/services/storage"
import { useCallback, useEffect, useMemo } from "react"
import useProfile from "~/hooks/useProfile"
import useLoading from "~/hooks/useLoading"
import { getProfilesAPI } from "~/services/api/profile"
import {
    getTypeForumID,
    getTypeUserRole,
} from "~/services/api/forum"
import { formatarDataISO } from "~/services/utils"
import { ModalCreatTypeForum } from "./ModalCreatTypeForum"

type IroleUser = {
    id: string;
    type_vote: string,
    first_name: string,
    last_name?: string
    created_on?: string

}

type ItypeForm = {
    name: string;
    description: string;
    requirements: string;
    has_fund: boolean;
};

export const SummaryForum = () => {

    const navigate = useNavigate()

    const { setProfiles } = useProfile()

    const { setLoading } = useLoading();

    const location = useLocation();

    const { id_type_forum } = location.state || {};

    const {
        setParticipantList,
        participantList,
        createForumModal,
        setCreateForumModal,
        editForumModal,
        setEditForumModal,
        participantMandatory,
        setOnlyTypeForum,
        onlyTypeForum,
        setIsForumTypePresent,
        setModalCreatTypeForum,
        modalCreatTypeForum
    } = useForum()

    const fetchParticipantMandatoryForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeUserRole(id_type_forum)

            setParticipantList(data.results)

        } catch {

            setParticipantList([])

        } finally {

            setLoading(false);
        }
    }, [id_type_forum, participantList])


    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {

            console.error(err);

        } finally {

            setLoading(false);
        }
    }, []);


    const fetchOnlyTypeForum = useCallback(async () => {

        try {
            const { data } = await getTypeForumID(Number(id_type_forum))

            setOnlyTypeForum(data)

            setIsForumTypePresent(true)

        } catch (error) {

            setIsForumTypePresent(false)

            console.log(error)
        }

    }, [onlyTypeForum, id_type_forum])


    function getFormTypeForum(): ItypeForm | null {

        const form_type_forum = AppStorage.get("@form_type_forum");

        if (form_type_forum) {
            let parsedData: ItypeForm | null;
            try {
                parsedData = JSON.parse(String(form_type_forum)) as ItypeForm;
            } catch (error) {
                console.error("Erro ao parsear o JSON:", error);
                parsedData = null;
            }
            return parsedData;
        }
        return null;
    }

    const result = getFormTypeForum();

    const filterParticipants = participantList?.map((users) => ({
        id: String(users.user.id),
        last_name: String(users.user.last_name),
        first_name: String(users.user.first_name),
        type_vote: users.type_vote,
        created_on: users.created_on
    }))

    const listProfilesvote = useMemo(() => {
        const participants: IroleUser[] = Array.isArray(participantMandatory)
            ? participantMandatory
            : [];
        const type_vot: { [key: string]: string } = {
            voto_com_veto: "Vota Com Veto",
            vota: "Vota",
            nao_vota: "Não Vota"
        }
        const renderList = [
            ...new Map([...(filterParticipants || []),
            ...participants].map(item => [item.id, item])).values()
        ];
        return renderList.map((user) => ({
            name: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : "Não Encontrado",
            "Tipo de voto": type_vot[user.type_vote],
            "Data de adição": formatarDataISO(String(user?.created_on))
        }));
    }, [participantMandatory, participantList]);


    useEffect(() => {
        fetchProfiles()
        fetchParticipantMandatoryForum()
        fetchOnlyTypeForum()
    }, [])


    return (
        <div>
            <div className="w-full mx-auto">
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Forum", onClick: () => navigate("/app/forum/") },
                                    { crumb: "" },
                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            Resumo do tipo de fórum
                        </h2>
                    </div>
                </div>
            </div>
            <div className="w-[95%]  mx-auto">
                <Margin top={30} />
                <h4>{result?.name}</h4>
                <Margin top={30} />
                <div className="w-[100%]">
                    <section>
                        <h4>Descrição</h4>
                        <p>{result?.description}</p>
                    </section>
                    <Margin top={30} />
                    <section>
                        <h4>Requisitos</h4>
                        <p>{result?.requirements}</p>
                    </section>
                    <Margin top={30} />
                    <section className="flex">
                        <div className="w-[100%]">
                            <TableBasic
                                columnTitles={["Nome", "Tipo de voto", "Data de adição"]}
                                rowData={listProfilesvote || []}
                                pageSizes={[5]}
                            />
                        </div>
                    </section>
                    <div className="flex items-center justify-between w-[100%] mt-[30px]">
                        <div >
                            <Button
                                kind="ghost"
                                size="large"
                                label="Cancelar"
                                onClick={() => navigate("/app/forum/forum-type/")}
                            />
                        </div>
                        <div className="flex items-center gap-[10px]">
                            {/* {id_type_forum !== undefined && */}
                                <Button
                                    kind="tertiary"
                                    size="large"
                                    label="Editar tipo de fórum"
                                    onClick={() => setEditForumModal(true)}
                                />
                            {/* } */}
                            <Button
                                kind="primary"
                                size="large"
                                label={
                                    "Enviar para aprovação"

                                }
                                onClick={() => {
                                    // setCreateForumModal(true)
                                    // isForumTypePresent ?
                                    //     updateTypeForumAPI() :
                                    //     fetchTypeForumandRoleProfile()
                                    setModalCreatTypeForum(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SendTypeCreatForum
                open={createForumModal}
                onClose={() => setCreateForumModal(false)}
            />
            <ModalEditTypeForum
                open={editForumModal}
                onClose={() => setEditForumModal(false)}
            />
            <ModalCreatTypeForum
                onClose={() => setModalCreatTypeForum(false)}
                open={modalCreatTypeForum}
            />
        </div>
    )
}
