import React from "react";
import styled from "styled-components";
import {
  firstProgress,
  secondProgress,
  thirdProgress
} from "~/assets/IconsSVG"
interface Step {
  label: string;
  completed?: boolean;
  current?: boolean;
}

interface ProgressStepsProps {
  steps: Step[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
 border-top: 4px solid #C6C6C6;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const StepItem = styled.div<{ completed: boolean; current: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content:start;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -4px;
  border-top: ${({ completed, current }) => (
    completed || current ? "4px solid rgb(10, 74, 135)"
      : "none")};
      transition: .1s;
`;

const Circle = styled.div<{ completed: boolean; current: boolean }>`
  z-index: 1;
`;

const Label = styled.span<{ current: boolean }>`
  margin-top: 0px;
  font-size: 14px;
  text-align: center;
  max-width: 300px;
`;

const ProgressIndicator: React.FC<ProgressStepsProps> = ({ steps }) => {
  return (
    <Container>
      <StepsContainer>
        {steps.map((step, index) => (
          <StepItem
            key={index}
            completed={!!step.completed}
            current={!!step.current}
          >
            <div className="flex items-center pt-[10px] gap-[5px] w-[100%]">
              <Circle completed={!!step.completed} current={!!step.current}>
                {step.completed
                  ? thirdProgress
                  : step.current
                    ? secondProgress
                    : firstProgress}
              </Circle>
              <Label current={!!step.current}>{step.label}</Label>
            </div>
          </StepItem>
        ))}
      </StepsContainer>
    </Container>
  );
};

export default ProgressIndicator;
