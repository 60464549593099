import styled from "styled-components";
import { verifyIcon } from "~/assets/IconsSVG"

const FileItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border-radius: var(--Default, 8px);
  background: var(--Layer-layer-01, #f4f4f4);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  flex-direction: row;
  margin-right: 10px;
`;

const FileName = styled.span`
  color: var(--Text-text-primary, #161616);
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  color: var(--Text-text-primary, #161616);
  font-size: 20px;
  margin-left: 8px;
`;

export const FileItem = ({ fileName }: { fileName: string }) => {
  return (
    <FileItemContainer>
      <FileName>{fileName}</FileName>
      <IconContainer>
        {verifyIcon}
      </IconContainer>
    </FileItemContainer>
  );
};


