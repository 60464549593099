import { Button, Dropdown, InputFluid, Margin } from "prosperita-dumbo-react";
import userIcon from "~/assets/User.png";
import { arrowIcon } from "~/assets/IconsSVG";
import Pagination from "~/components/Pagination";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import useForum from "~/hooks/useForum";
import useLoading from "~/hooks/useLoading";
import { useNavigate } from "react-router-dom";
import useProfile from "~/hooks/useProfile";
import { getProfilesAPI } from "~/services/api/profile";
import { formatarDataISO } from "~/services/utils";
import { getForumCommentView } from "~/services/api/forum";
import { filterIcon } from "~/assets/IconsSVG"
import { ActivitiesProps } from "../Activities/Activities";
import emptyState from "~/assets/EmptyState.png";

const SectionTopic = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  outline:none;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid #2370ff;
  }
  border-radius: 8px;
`;

export const Topical = ({ userNotIvite }: ActivitiesProps) => {

    const navigate = useNavigate()

    const {
        listTopic,
        setlistTopicOnly,
        setCommentTopicListView,
        commentTopicListView,
        filterForumTopic
    } = useForum()

    const { setLoading } = useLoading();

    const {
        setProfiles,
        profiles
    } = useProfile();


    const [currentPage, setCurrentPage] = useState(1);
    const topicsPerPage = 2;
    const startIndex = (currentPage - 1) * topicsPerPage;
    const currentTopics = listTopic?.slice(startIndex, startIndex + topicsPerPage);


    const fetchProfiles = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {

            console.error(err);

        } finally {

            setLoading(false);
        }
    }, []);

    const fetchCommentsForum = useCallback(async () => {

        setLoading(true);

        try {

            const { data } = await getForumCommentView()

            setCommentTopicListView(data.results)

        } catch {

        } finally {

            setLoading(false);
        }
    }, [commentTopicListView])

    console.log(currentTopics?.length)

    useEffect(() => {
        fetchProfiles()
        fetchCommentsForum()
    }, [])

    return (
        <div className="w-[100%]">
            {filterForumTopic &&
                <div className="h-[auto] bg-[#f4f4f4] mt-[50px] p-[8px]">
                    <div className="flex items-center w-[98%] justify-start mx-auto">
                        <h4 className="py-[10px]">Filtros</h4>
                    </div>
                    <div className="h-[60%] flex items-center w-[100%] justify-center">
                        <div style={{ borderBottom: "2px solid #8D8D8D" }}
                            className="flex items-center w-[99%] m-0 p-0 gap-[5px]">
                            <Dropdown
                                kind="fluid"
                                options={[]}
                                size="large"
                                label="Ordenar por"
                                placeholder="Ordenar por"
                                noRadius
                                borderNone
                            />
                            <Dropdown
                                kind="fluid"
                                options={[]}
                                size="large"
                                label="Selecione o período"
                                placeholder="Últimos 15 dias"
                                noRadius
                                borderNone
                            />
                            <div className="flex w-[100%] gap-[5px]">
                                <InputFluid
                                    type="date"
                                    label="Selecione o período"
                                    placeholder="Últimos 15 dias"
                                    noRadius
                                    borderNone
                                />
                                <InputFluid
                                    type="date"
                                    label="Selecione o período"
                                    placeholder="Últimos 15 dias"
                                    noRadius
                                    borderNone
                                />
                            </div>
                        </div>
                    </div>
                    <div className="my-[20px] flex justify-end">
                        <Button
                            kind="tertiary"
                            size="large"
                            label="Aplicar filtros"
                            hasIconSvg
                            icon={filterIcon}
                            onClick={() => ""}
                        />
                    </div>
                </div>}
            <Margin top={30} />
            <>
                {currentTopics && currentTopics?.length > 0 ?
                    <div>
                        {currentTopics && currentTopics?.map((topic, key) => {

                            const matchingProfiles = profiles.filter(profile => profile?.id === topic?.user);

                            const commentsViewFiltered = commentTopicListView?.filter((comments) => comments?.topic === topic?.id)

                            const hoje = new Date();

                            const dataCriacao = new Date(topic?.created_on);

                            const isMaisRecente = hoje.toDateString() === dataCriacao.toDateString()

                            return (
                                <div key={key}>
                                    <Margin top={30} />
                                    <div className="flex items-center m-0 p-0 gap-[22px]">
                                        <div className="bg-[#0F62FE] p-[15px]" style={{ borderRadius: "100px" }}>
                                            <img src={userIcon} />
                                        </div>
                                        <div className="section m-0 p-0">
                                            <h2
                                                style={{ fontFamily: "IBM Plex Sans" }}
                                                className="text-[18px] m-0 font-[800]"
                                            >
                                                {matchingProfiles?.map(profile => (
                                                    <span key={profile?.id}>
                                                        {profile?.name}
                                                    </span>
                                                ))}
                                            </h2>
                                            <h4
                                                style={{ fontFamily: "IBM Plex Sans" }}
                                                className="text-[17px] font-[400] m-0 mt-1"
                                            >
                                                {formatarDataISO(String(topic?.created_on))}
                                            </h4>
                                        </div>
                                        <div style={{ borderRadius: "15px", fontFamily: "IBM Plex Sans" }} className="section m-0 p-1 bg-[#c6c6c6]">
                                            {isMaisRecente ? "Mais recente" : "Outros"}
                                        </div>
                                    </div>
                                    <Margin top={30} />
                                    <SectionTopic
                                        className="w-[100%] bg-[#f4f4f4] p-[20px]" tabIndex={5}
                                        onClick={!userNotIvite ? () => {
                                            setlistTopicOnly(topic)
                                            navigate("/app/forum/comments", {
                                                state: {
                                                    forum: topic?.forum,
                                                    topic: topic.id,
                                                },
                                            });
                                        } : () => ""}
                                    >
                                        <div className="w-[100%]">
                                            <div className="w-[100%] justify-start flex flex-col">
                                                <p className="text-[25px] break-words">{topic.title}</p>
                                                <p className="text-[20px] break-words">{topic.content}</p>
                                            </div>
                                            <div className="flex justify-end gap-[250px] my-[20px]">
                                                <div>
                                                    <p>{commentsViewFiltered?.length}</p>
                                                    <p>visualizações</p>
                                                </div>
                                                <div>
                                                    <p>{commentsViewFiltered?.length}</p>
                                                    <p>respostas</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-[100%] bg-[#c6c6c6] h-[3px]"></div>
                                        <div className="w-[100%] flex justify-end mt-[30px]">{arrowIcon}</div>
                                    </SectionTopic>
                                </div>
                            )
                        })}
                        <Margin top={50} />
                        <div className="w-[100%] mx-auto flex justify-end">
                            <Pagination
                                totalPages={listTopic ? Math.ceil(listTopic?.length / topicsPerPage) : 0}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />
                        </div>
                    </div> :
                    <div className="w-[95%] mx-auto">
                        <div className="flex justify-between w-[100%] items-start mt-[50px]">
                            <img src={emptyState} className="w-[200px]" />
                        </div>
                        <div className="w-[100%] mt-[50px]">
                            <Margin top={20} />
                            <h5>Não há conteúdo disponível</h5>
                            <Margin top={20} />
                            <p>Atualmente, não há nada para exibir. Volte mais tarde ou adicione novo conteúdo.</p>
                        </div>
                    </div>
                }
            </>
            <Margin top={60} />
        </div>
    );
};
