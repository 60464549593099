import {
  Dropdown,
  InputFluid,
  InputSelect,
  Modal,
  Notification,
  Radio,
} from "prosperita-dumbo-react";
import addicon from "~/assets/iconadd.png";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import * as Yup from "yup";
import { useFormik } from "formik";
import useProfile from "~/hooks/useProfile";
import {
  getProfilesAPI,
  IPatchProfileBody,
  patchProfileAPI,
} from "~/services/api/profile";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import "typeface-ibm-plex-mono";

type Imodal = {
  open: boolean;
  onClose?: () => void;
};

type Values = {
  full_name: string;
  document_number: string;
  company_name: string;
  company_document_number: string;
  email: string;
  company: string;
  type_profile: string[];
  status: string;
  payment_company_approver: boolean;
  payment_approver: boolean;
  beneficiary_approver: boolean;
  permission:
  | "payment_approver"
  | "payment_company_approver"
  | "beneficiary_approver"
  | "no_permission"
  | "";
};

const initialValues: Values = {
  full_name: "",
  document_number: "",
  company_name: "",
  company_document_number: "",
  email: "",
  company: "",
  type_profile: [""],
  status: "",
  payment_company_approver: false,
  payment_approver: false,
  beneficiary_approver: false,
  permission: "",
};

const validationSchema = Yup.object({
  full_name: Yup.string().required("Nome completo é obrigatório"),
  document_number: Yup.string()
    .matches(/^\d+$/, "Número do documento deve conter apenas números")
    .required("Número do documento é obrigatório"),
  company_name: Yup.string().required("Nome da empresa é obrigatório"),
  company_document_number: Yup.string()
    .matches(
      /^\d+$/,
      "Número do documento da empresa deve conter apenas números"
    )
    .required("Número do documento da empresa é obrigatório"),
  brand_name: Yup.string().required("Nome da marca é obrigatório"),
  email: Yup.string()
    .email("Email inválido")
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Email deve ser um formato válido"
    ) // Verifica se o email está em um formato válido
    .required("Email é obrigatório"),
  profile: Yup.string().required("Usuário é obrigatório"),
  type_profile: Yup.string().required("Tipo de usuário é obrigatório"),
});

type Idropdown = {
  value: string;
  label: string;
};

const options: Idropdown[] = [
  {
    value: "tool_manager",
    label: "Gestor de ferramenta",
  },
  {
    value: "area_manager",
    label: "Gestor de àrea",
  },
  {
    value: "internal",
    label: "Atendente",
  },
];

type INotification = {
  status: "success" | "warning" | "info" | "error";
  message: string;
  title: string;
  active: boolean;
};

export const EditModalProfile = ({ open, onClose }: Imodal) => {

  const { setLoading } = useLoading();

  const [activeRadio, setActiveRadio] = useState<string | null>(null);

  const [notificationDetails, setNotificationDetails] = useState<INotification>(
    {
      status: "info",
      message: "",
      title: "",
      active: false,
    }
  );
  const { setProfiles, setEditModalProfile, detailsProfile } = useProfile();

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const { values, handleChange, touched, errors, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => { },
    });

  const handleChangeSelect = (event: { label: string; value: string }[]) => {
    const selectedValues = event.map((perm) => perm.value);
    setFieldValue("type_profile", selectedValues);
    console.log(selectedValues);
  };

  const payload: IPatchProfileBody = useMemo(() => {
    return {
      name: values.full_name,
      document_number: onlyNumbers(values.document_number),
      type: values.type_profile,
      raw_data: {
        first_login: detailsProfile?.raw_data?.first_login,
        status: values.status,
      },
      user: {
        email: values.email,
      },
      payment_company_approver:
        values.permission === "payment_company_approver"
          ? true
          : values.permission === "no_permission" && false,
      payment_approver:
        values.permission === "payment_approver"
          ? true
          : values.permission === "no_permission" && false,
      beneficiary_approver:
        values.permission === "beneficiary_approver"
          ? true
          : values.permission === "no_permission" && false,
    };
  }, [values]);

  // const disabledButtonWhenTypeIsClient = useMemo(() => {
  //     const isFullNameValid = payload.name && payload.name.trim().split(/\s+/).length >= 2;
  //     const isDocumentNumberValid = payload.document_number.length >= 11;
  //     const isEmailValid = Boolean(payload.email) && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(payload.email);
  //     const isTypeProfileValid = payload.type && payload.type[0];
  //     return (
  //         !isFullNameValid ||
  //         !isDocumentNumberValid ||
  //         !isEmailValid ||
  //         !isTypeProfileValid
  //     );
  // }, [payload, values]);


  const fetchProfile = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await patchProfileAPI(payload, id);
        setEditModalProfile(false);
        setLoading(false);
        setNotificationDetails({
          status: "success",
          message: "O tipo de perfil do usuário  foi editado com sucesso.",
          title: "Usuário editado com sucesso",
          active: true,
        });
        setTimeout(() => {
          setNotificationDetails((prev) => ({
            ...prev,
            active: false,
          }));
        }, 5000);
        resetForm();
      } catch (err: unknown) {
        console.error(err);

        setNotificationDetails({
          status: "error",
          message:
            "Não foi possível editar o tipo de perfil do usuário no momento, tente novamente mais tarde ou contate o administrador do sistema",
          title: "Falha ao editar usuário",
          active: true,
        });
        setTimeout(() => {
          setNotificationDetails((prev) => ({
            ...prev,
            active: false,
          }));

        }, 5000);
        setLoading(false);
      } finally {
        fetchProfiles();
        resetForm();
      }
    },
    [setLoading, fetchProfiles, payload]
  );

  useEffect(() => {
    setFieldValue("document_number", detailsProfile?.document_number);
    setFieldValue("full_name", detailsProfile?.name);
    setFieldValue("status", detailsProfile?.raw_data?.status);
    setFieldValue("type_profile", detailsProfile?.type?.[0]);
    setFieldValue("email", detailsProfile?.user.email);
  }, [detailsProfile]);


  useEffect(() => {
    // Verifica se o status existe antes de atualizar o estado
    if (detailsProfile?.raw_data?.status) {
      setActiveRadio(detailsProfile.raw_data.status); // Preenche o estado inicial
    } else {
      setActiveRadio(null); // Garante que o estado seja resetado caso não tenha status
    }
  }, [detailsProfile]); // Reexecuta se o detailsProfile mudar

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setActiveRadio(value); // Atualiza o estado do radio selecionado
    setFieldValue("status", value); // Atualiza o valor no formulário
  };


  return (
    <>
      <Modal
        onClose={() => {
          onClose && onClose();
          resetForm();
          setActiveRadio(null)
        }}
        open={open}
        height="500px"
        width="50%"
        overflow
        label={
          <>
            <div>
              <h4 className="m-0 p-0">Editar usuário</h4>
              <Margin mt={10} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[700] font-sans text-[21px]"
              >
                {detailsProfile?.name}
              </h4>
              <Margin mt={6} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[400] text-[18px]"
              >
                {formatCPFCNPJ(String(detailsProfile?.document_number))}
              </h4>
              <Margin mt={5} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 text-[16px] font-[400]"
              >
                {detailsProfile?.user.email}
              </h4>
            </div>
          </>
        }
        background="#f4f4f4"
        buttons={[
          {
            label: "Cancelar",
            kind: "ghost",
            dimension: "74",
            onClick: () => {
              resetForm();
              setEditModalProfile(false);
              setActiveRadio(null)
            },
          },
          {
            label: "Salvar edição",
            icon: addicon,
            dimension: "74",
            kind: "primary",
            onClick: () => fetchProfile(String(detailsProfile?.id)),
            disabled: false,
          },
        ]}
      >
        <div className="mt-2 bg-[#c6c6c6] w-[100%] h-[2px]"></div>
        <div style={{}} className="w-[99%] mx-auto mt-0">
          <Padding pt={15} />
          <div className="flex w-[100%] gap-[30px] justify-start">
            <div className="ml-[10px]">
              <Radio
                label="Inativo"
                name="status"
                checked={activeRadio === "inactive"}
                value={"inactive"}
                onChange={handleToggleChange}
              />
            </div>
            <div>
              <Radio
                label="Ativo"
                name="status"
                checked={activeRadio === "active"}
                value={"active"}
                onChange={handleToggleChange}
              />
            </div>
          </div>
          <Margin mb={10} />
          <InputFluid
            label="Nome/razão social completo"
            borderNone
            noRadius
            type="text"
            id="full_name"
            name="full_name"
            placeholder="Digite o nome completo"
            onChange={(event) => {
              setFieldValue("full_name", event.target.value);
            }}
            value={values.full_name}
            error={(errors.full_name && touched.full_name) || undefined}
            message={errors.full_name}
          />
          <div className="flex justify-center mt-1">
            <InputFluid
              label="CPF"
              borderNone
              noRadius
              maxLength={16}
              id="document_number"
              onChange={handleChange}
              name="document_number"
              placeholder="00.000.000/0001-00"
              value={values.document_number}
              message={errors.document_number}
            />
            <Margin mr={2} />
            <InputFluid
              label="E-mail"
              borderNone
              noRadius
              placeholder="Digite o e-mail"
              id="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              message={errors.email}
            />
          </div>
          <Margin mb={5} />
          <InputSelect
            kind="fluid"
            isMulti
            label="Editar Perfil"
            options={options}
            size="large"
            name="type_profile"
            placeholder="Selecione o perfil"
            onChange={(event) =>
              handleChangeSelect(event as { label: string; value: string }[])
            }
            noRadius
          />
          <Margin mb={5} />
          <Dropdown
            kind="fluid"
            label="Editar Permissoes"
            options={[
              {
                label: "Aprovador(a) de Pagamentos",
                value: "payment_approver",
              },
              {
                label: "Aprovador(a) de Beneficiários",
                value: "beneficiary_approver",
              },
              {
                label: "Aprovador(a) de Empresas",
                value: "payment_company_approver",
              },
              {
                label: "Nenhuma Permissão",
                value: "no_permission",
              },
            ]}
            size="large"
            name="permission"
            placeholder="Selecione Permissoes"
            onChange={handleChange}
            noRadius
          />
        </div>
        <Margin mt={250} />
      </Modal>
      <Notification
        zindex={notificationDetails.status === "error" ? "10" : "8"}
        top={"130px"}
        right="20px"
        onClose={() =>
          setNotificationDetails((prev) => ({
            ...prev,
            active: false,
          }))
        }
        noRadius
        status={notificationDetails.status}
        actived={notificationDetails.active}
        title={notificationDetails.title}
        message={notificationDetails.message}
      >
      </Notification>
    </>
  );
};
