import {
  Grid,
  Column,
  Form,
  Heading,
  PasswordInput,
  Stack,
  InlineNotification,
} from "@carbon/react";
import LoginIcon from "~/assets/Login.png";
import PasswordIcon from "~/assets/Password.png";
import BanvoxLogo from "~/assets/banvox.png";
import BgLogin from "~/assets/bgLogin.png";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getRefreshTokenAPI, getTokenAPI } from "~/services/api/auth";
import { useEffect, useState } from "react";
import AppStorage from "~/services/storage";
import useLoading from "~/hooks/useLoading";
import useUser from "~/hooks/useUser";

import { onlyNumbers } from "~/services/utils";
import { Button } from "prosperita-dumbo-react";
import MaskedTextInputDumbo from "~/components/MaskedTextInputDumbo";
import { ForgotPasswordModal } from "~/pages/auth/ForgotPasswordModal";
// import { ForgotPasswordModal } from "./ForgotPasswordModal";

export const WelcomeText = styled.span`
  color: black;
  font-size: 42px;
  font-weight: 100;
`;

export const WelcomeDescription = styled.span`
  color: #525252;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const LoginContainer = styled.div`
  min-height: 100vh;

  .right-image {
    width: 100%;
    height: 100vh;

    background-image: url(${BgLogin});

    background-repeat: no-repeat;
    background-position-x: end;
    background-position-y: center;
  }

  .form-btn {
    width: 100%;
    max-width: 100%;
  }

  .header {
    z-index: 1;
  }
`;

const StyledPasswordInput = styled.div`
  .custom-password-input {
    height: 50px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    border: 2px solid transparent;
    border-bottom: 1px solid gray;
    background: #f4f4f4;
    color: #333333;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    line-height: 18px;
    outline: none;

    &:focus {
      border: 2px solid #0072c6; /* ajuste conforme necessário */
    }

    &::placeholder {
      color: #9b9898;
    }
  }
`;

type Values = {
  username: string;
  password: string;
};

const initialValues: Values = {
  username: "",
  password: "",
};

const FormSchema = Yup.object().shape({
  username: Yup.string().required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

const AuthenticateFund = () => {
  const navigate = useNavigate();
  const { setPass } = useUser();
  const [error, setError] = useState<string>();
  const { setLoading } = useLoading();
  const [rememberMe, setRememberMe] = useState<boolean | undefined>(false);

  const { setIsForgotPasswordModalOpen, isForgotPasswordModalOpen } = useUser();

  console.log(isForgotPasswordModalOpen);

  const fetchRefreshToken = async () => {
    const refresh = AppStorage.getRefresh();
    const payload = { refresh: refresh };

    try {
      const { data } = await getRefreshTokenAPI(payload);

      AppStorage.setToken(data.access);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSend = async (values: Values) => {
    setLoading(true);
    if (rememberMe) {
      AppStorage.set("@app:username", values.username);
    } else {
      AppStorage.remove("@app:username");
    }

    try {
      const payload = {
        username: onlyNumbers(values.username),
        password: values.password,
      };

      const {
        data: { access, refresh },
      } = await getTokenAPI(payload);

      AppStorage.setToken(access);
      AppStorage.setRefresh(refresh);

      if (access && refresh) {
        await fetchRefreshToken();
        setLoading(false);
        navigate("/app");
        setPass(values.password);
        AppStorage.set("@app:pass", values.password);
      }
    } catch (error) {
      console.error("Error while trying to login:", error);
      setError(String(error));
      setLoading(false);
    }
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: FormSchema,
      onSubmit: handleSend,
    });

  useEffect(() => {
    const storedUsername = AppStorage.get("@app:username");
    if (storedUsername) {
      setFieldValue("username", storedUsername);
    }

    const storedRememberMe = AppStorage.get("@app:rememberMe");
    if (storedRememberMe !== null) {
      setRememberMe(storedRememberMe === "true");
    }
  }, [setFieldValue]);

  return (
    <LoginContainer>
      <Grid>
        <Column lg={5}>
          <Padding pt={40} x={16}>
            <Form onSubmit={handleSubmit}>
              <Margin mb={42} />
              <img
                style={{ height: "90px", position: "relative", left: "-10px" }}
                src={BanvoxLogo}
                alt=""
              />
              <Margin mb={-20} />
              <Heading style={{ fontSize: "20px" }}>Banking</Heading>
              <Margin mt={20} />
              <div>
                <WelcomeText>Bem vindo</WelcomeText>
              </div>
              <WelcomeDescription>
                Faça login para concluir a autenticação do fundo FIDIC INVEST
              </WelcomeDescription>
              <Margin mb={40} />
              <Stack gap={5}>
                {error && (
                  <InlineNotification
                    title="Erro!"
                    // subtitle={error}
                    subtitle="Nenhuma conta encontrada com as credênciais fornecidas."
                    hideCloseButton
                    kind="error"
                    lowContrast
                  />
                )}

                <p
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "300",
                  }}
                  className="m-0 p-0"
                >
                  Informe seu CPF
                </p>

                <MaskedTextInputDumbo
                  id="username"
                  mask="999.999.999-99"
                  onChange={handleChange("username")}
                  placeholder="000.000.000-00"
                  value={values.username}
                  className="login"
                />

                <div className="relative flex flex-col">
                  <StyledPasswordInput>
                    <PasswordInput
                      id="password"
                      labelText="Insira sua senha"
                      placeholder="Senha"
                      type="password"
                      onChange={handleChange("password")}
                      value={values.password}
                      helperText={errors.password}
                      required
                      className="custom-password-input"
                    />
                  </StyledPasswordInput>
                </div>
                <Margin mt={40} />
                <Button
                  label="Entrar"
                  kind="primary"
                  size="large"
                  icon={LoginIcon}
                />

                <Button
                  label="Esqueci minha senha"
                  kind="tertiary"
                  size="large"
                  icon={PasswordIcon}
                  onClick={() => setIsForgotPasswordModalOpen((prev) => !prev)}
                />
              </Stack>
            </Form>
          </Padding>
        </Column>
        <Column lg={11}>
          <div className="right-image"></div>
        </Column>
      </Grid>
      <ForgotPasswordModal
        open={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
      />
    </LoginContainer>
  );
};

export default AuthenticateFund;
