import ApiService from "../api";

type IforumRole = {
    user_status: string,
    type_vote: string,
    forum_type: number,
    user: number
}
type ITypeforum = {
    has_fund: boolean;
    name: string
    description: string
    requirements: string
}
export type IroleForumType = {
    created_on: string;
    deleted: string | null;
    deleted_by_cascade: boolean;
    forum_type: number;
    id: number;
    type_vote: string;
    updated_on: string;
    user: { id: number, first_name: string, last_name?: string };
    user_status: string;
}

type IresponseTypeForum = {
    id: number;
    deleted: string | null;
    deleted_by_cascade: boolean;
    has_fund: boolean;
    name: string;
    description: string;
    requirements: string;
    created_at: string;
    updated_at: string;
}

export type resultsTypeForum = {
    created_at: string;
    deleted: string | null;
    deleted_by_cascade: boolean;
    description: string;
    has_fund: boolean;
    id: number;
    name: string;
    requirements: string;
    updated_at: string;
}

export type IforumType = {
    count: number,
    next: null
    previous: null,
    results: resultsTypeForum[]
}

export type ICreatForum = {
    forum_status?: "aberto" | "encerrado" | "aprovado" | "pendente_de_votacao" | "vetado",
    name: string,
    description: string,
    vote_deadline: string,
    deadline?: string,
    attend: string,
    forum_type: string
}

export type Forum = {
    id: string;
    deleted?: boolean;
    deleted_by_cascade?: boolean;
    forum_status: string;
    name: string;
    description?: string;
    vote_deadline: Date;
    deadline?: Date;
    created_on: Date;
    updated_on: Date;
    attend: number;
    forum_type: { id: number, name: string, description: string, has_fund: boolean, created_at: string, requirements: string };
};

export type IForum = {
    count: number,
    next: null
    previous: null,
    results: Forum[]
}

export type ItypeForumTopic = {
    title: string,
    content: string,
    forum: number,
    user: number
}

export type IgetTypeForumTopic = {
    id: number,
    deleted: string,
    deleted_by_cascade: boolean,
    title: string,
    content: string
    created_on: string
    updated_on: string
    forum: string,
    user: number
}

export type IforumAttachment = {
    forum_type: string,
    forum: number,
    topic: number,
    file: unknown
}

export type IforumVote = {
    created_on: string;
    deleted: string | null;
    deleted_by_cascade: boolean;
    forum: number;
    forum_type: number;
    id: number;
    updated_on: string;
    user: number;
    forum_type_user_roles: IroleForumType[]
    vote_decision: "aprovado" | "rejeitado" | "pendente";
    vote_status: "voto_realizado" | "voto_pendente" | "voto_cancelado";
}


export type IcommentTopicForum = {
    content: string;
    created_on: string;
    deleted: string | null;
    deleted_by_cascade: boolean;
    forum: number;
    id: number;
    topic: number;
    updated_on: string;
    user: number;
}


export const getTypeUserRole = (forum_type?: number) => {
    return ApiService.HttpGet<{ results: IroleForumType[] }>({
        route: "forum/forum-type-user-role/",
        token: true,
        params: { forum_type }
    });
};

export const getTypeUsersRoles = () => {
    return ApiService.HttpGet<{ results: IroleForumType[] }>({
        route: "forum/forum-type-user-role/",
        token: true,
    });
};

export const postTypeUserRole = (body: IforumRole) => {
    return ApiService.HttpPost<unknown>({
        route: "forum/forum-type-user-role/",
        token: true,
        body
    });
};

export const deleteTypeUserRoleAPI = (id: number) => {
    return ApiService.HttpDelete<unknown>({
        route: `forum/forum-type-user-role/${id}`,
        token: true,
    });
};

export const getUserOnlyRole = (id: number) => {
    return ApiService.HttpGet<unknown>({
        route: `forum/forum-type-user-role/${id}`,
        token: true,
    });
};

export const postCreatTypeForum = (body: ITypeforum) => {
    return ApiService.HttpPost<IresponseTypeForum>({
        route: "forum/forum-type/",
        token: true,
        body
    });
};

export const getTypeForum = () => {
    return ApiService.HttpGet<{ results: resultsTypeForum[] }>({
        route: "forum/forum-type/",
        token: true,
    });
};

export const deleteTypeForumAPI = (id: number) => {
    return ApiService.HttpDelete({
        route: `forum/forum-type/${id}/`,
        token: true,
    });
};

export const getTypeForumID = (id: number) => {
    return ApiService.HttpGet<resultsTypeForum>({
        route: `forum/forum-type/${id}/`,
        token: true,
    });
};

export const patchTypeForum = (id: number, body: ITypeforum) => {
    return ApiService.HttpPatch<resultsTypeForum>({
        route: `forum/forum-type/${id}/`,
        token: true,
        body
    });
};

export const postCreateForum = (body: ICreatForum) => {
    return ApiService.HttpPost<Forum>({
        route: `forum/forum/`,
        token: true,
        body
    });
};

export const getForumAPI = () => {
    return ApiService.HttpGet<IForum>({
        route: `forum/forum/`,
        token: true,
    });
};
export const getForumFilterAPI = (
    forum_type?: number,
    forum_status?: string
) => {
    const params: {
        forum_status?: string;
        forum_type?: number;
    } = {};

    if (forum_type) {
        params.forum_type = forum_type;
    }
    if (forum_status) {
        params.forum_status = forum_status;
    }
    return ApiService.HttpGet<IForum>({
        route: `forum/forum/`,
        token: true,
        params,
    });
};

export const getForumFilteredAPI = (id: number) => {
    return ApiService.HttpGet<Forum>({
        route: `forum/forum/${id}`,
        token: true,
    });
};
export const pacthForumFilteredAPI = (id: number, body: Omit<ICreatForum, "description" | "deadline">) => {
    return ApiService.HttpPatch<Forum>({
        route: `forum/forum/${id}/`,
        token: true,
        body
    });
};

export const deleteForumAPI = (id: number) => {
    return ApiService.HttpDelete<unknown>({
        route: `forum/forum/${id}`,
        token: true,
    });
};

export const postForumTopicAPI = (body: ItypeForumTopic) => {
    return ApiService.HttpPost<IgetTypeForumTopic>({
        route: `forum/forum-topic/`,
        token: true,
        body
    });
};

export const getForumTopic = (forum: number) => {
    return ApiService.HttpGet<{ results: IgetTypeForumTopic[] }>({
        route: `forum/forum-topic/`,
        token: true,
        params: { forum }
    });
};

export const getForumTopicOnly = (id: number) => {
    return ApiService.HttpGet<IgetTypeForumTopic>({
        route: `forum/forum-topic/${id}`,
        token: true,
    });
};


export const postForumAttachment = (body: IforumAttachment) => {
    return ApiService.HttpPost<unknown>({
        route: `forum/forum-attachment/`,
        // customHeaders: {
        //     "Content-Type": "multipart/form-data",
        // },
        token: true,
        body
    });
};

export const getForumAttachment = (forum: number) => {
    return ApiService.HttpGet<unknown>({
        route: `forum/forum-attachment/`,
        token: true,
        params: { forum }
    });
};

export const postForumComment = (body: {
    forum: number,
    topic: number,
    content: string,
    user: number
}) => {
    return ApiService.HttpPost<unknown>({
        route: `forum/forum-comment/`,
        token: true,
        body
    });
};

export const getForumComment = (topic: number) => {
    return ApiService.HttpGet<{ results: IcommentTopicForum[] }>({
        route: `forum/forum-comment/`,
        token: true,
        params: { topic }
    });
};

export const getForumCommentView = () => {
    return ApiService.HttpGet<{ results: IcommentTopicForum[] }>({
        route: `forum/forum-comment/`,
        token: true,
    });
}

export const postForumVoteUser = (body: {
    vote_status: string,
    vote_decision: string,
    forum_type: number,
    forum: number,
    user: number,
}) => {
    return ApiService.HttpPost<unknown>({
        route: `forum/forum-user-vote/`,
        token: true,
        body
    });
};

export const getForumVoteUser = (forum: number) => {
    return ApiService.HttpGet<{ results: IforumVote[] }>({
        route: `forum/forum-user-vote/`,
        token: true,
        params: { forum }
    });
};

export const deleteForumVoteUser = (id: number) => {
    return ApiService.HttpDelete<unknown>({
        route: `forum/forum-user-vote/${id}`,
        token: true,
    });
};