import {
  addHours,
  formatDistanceToNow,
  intervalToDuration,
  isAfter,
} from "date-fns";
import { ptBR } from "date-fns/locale";

export const hasLength = (str: string, length: number) => {
  return str.length >= length;
};

export const hasLowerCase = (str: string) => {
  return str.toUpperCase() !== str;
};

export const hasUpperCase = (str: string) => {
  return str.toLowerCase() !== str;
};

export const hasSpecialCharacter = (str: string) => {
  return /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(str);
};

export const hasNumber = (str: string) => {
  return /\d/.test(str);
};

export const passwordStrength = (password: string) => {
  const strengthSteps = [];

  if (password !== "") strengthSteps.push(0);
  if (password.length >= 8) strengthSteps.push(1);
  if (hasNumber(password)) strengthSteps.push(2);
  if (hasLowerCase(password) && hasUpperCase(password)) strengthSteps.push(3);
  if (hasSpecialCharacter(password)) strengthSteps.push(4);

  return strengthSteps;
};

export const passwordStrengthPercentage = (password: string) => {
  return (100 * passwordStrength(password).length) / 5;
};

export const passwordStrengthText = (password: string) => {
  const percentage = passwordStrengthPercentage(password);

  if (percentage > 60 && percentage < 90) {
    return "Sua senha precisa melhorar";
  } else if (percentage >= 90) {
    return "Sua senha parece bem segura";
  }

  return percentage > 0 ? "Sua senha está fraca" : "";
};

export const passwordLevel = (password: string) => {
  const percentage = passwordStrengthPercentage(password);

  if (percentage < 40) {
    return "easy";
  } else if (percentage >= 40 && percentage < 70) {
    return "medium";
  } else if (percentage >= 70) {
    return "hard";
  }
};

export const onlyNumbers = (str: string) => {
  return str?.replace(/\D/g, "");
};

export const getFirstLetters = (fullName: string) => {
  if (fullName !== null && fullName !== undefined && fullName !== "") {
    const names = fullName.split(" ");

    const initials = names
      .slice(0, 2)
      .filter((name) => name.length > 0)
      .map((name) => name.charAt(0))
      .join("");

    return initials;
  }
};

const isWeekdayAndWorkingHours = (): boolean => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  const currentHour = currentDate.getHours() + currentDate.getMinutes() / 60;

  const isWeekday = currentDay >= 1 && currentDay <= 5;

  const isWorkingHours = currentHour >= 7 && currentHour < 16.5;

  return isWeekday && isWorkingHours;
};

export const isWeekDay = isWeekdayAndWorkingHours();
export const isWorkingHours = isWeekdayAndWorkingHours();

const isWeekdayAndWorkingHoursPayments = (): boolean => {
  const currentDay = new Date().getDay();
  const currentHour = new Date().getHours();

  const isWeekdayPayments = currentDay >= 1 && currentDay <= 5;
  const isWorkingHoursPayments = currentHour >= 7 && currentHour < 21;

  return isWeekdayPayments && isWorkingHoursPayments;
};

export const isWeekDayPayments = isWeekdayAndWorkingHoursPayments();
export const isWorkingHoursPayments = isWeekdayAndWorkingHoursPayments();

export const verifyObjectIsEmpty = (obj: never) => {
  return Object.keys(obj).length === 0;
};

const create_array = (total: number, numero: number) =>
  Array.from(Array(total), () => number_random(numero));
const number_random = (number: number) => Math.round(Math.random() * number);
const mod = (dividendo: number, divisor: number) =>
  Math.round(dividendo - Math.floor(dividendo / divisor) * divisor);

export const generateCPF = (masked: boolean = false) => {
  const total_array = 9;
  const n = 9;
  const [n1, n2, n3, n4, n5, n6, n7, n8, n9] = create_array(total_array, n);

  let d1 =
    n9 * 2 +
    n8 * 3 +
    n7 * 4 +
    n6 * 5 +
    n5 * 6 +
    n4 * 7 +
    n3 * 8 +
    n2 * 9 +
    n1 * 10;
  d1 = 11 - mod(d1, 11);
  if (d1 >= 10) d1 = 0;

  let d2 =
    d1 * 2 +
    n9 * 3 +
    n8 * 4 +
    n7 * 5 +
    n6 * 6 +
    n5 * 7 +
    n4 * 8 +
    n3 * 9 +
    n2 * 10 +
    n1 * 11;
  d2 = 11 - mod(d2, 11);
  if (d2 >= 10) d2 = 0;

  if (masked) {
    return `${n1}${n2}${n3}.${n4}${n5}${n6}.${n7}${n8}${n9}-${d1}${d2}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${d1}${d2}`;
  }
};

export const generateCNPJ = (masked: boolean = false) => {
  const total_array = 8;
  const n = 9;
  const [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);
  const n9 = 0;
  const n10 = 0;
  const n11 = 0;
  const n12 = 1;

  let d1 =
    n12 * 2 +
    n11 * 3 +
    n10 * 4 +
    n9 * 5 +
    n8 * 6 +
    n7 * 7 +
    n6 * 8 +
    n5 * 9 +
    n4 * 2 +
    n3 * 3 +
    n2 * 4 +
    n1 * 5;
  d1 = 11 - mod(d1, 11);
  if (d1 >= 10) d1 = 0;

  let d2 =
    d1 * 2 +
    n12 * 3 +
    n11 * 4 +
    n10 * 5 +
    n9 * 6 +
    n8 * 7 +
    n7 * 8 +
    n6 * 9 +
    n5 * 2 +
    n4 * 3 +
    n3 * 4 +
    n2 * 5 +
    n1 * 6;
  d2 = 11 - mod(d2, 11);
  if (d2 >= 10) d2 = 0;

  if (masked) {
    return `${n1}${n2}.${n3}${n4}${n5}.${n6}${n7}${n8}/${n9}${n10}${n11}${n12}-${d1}${d2}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}${n9}${n10}${n11}${n12}${d1}${d2}`;
  }
};

export const generateCellphone = (
  masked: boolean = false,
  ddd: number = 11,
  prefix: number = 9
) => {
  const total_array = 8;
  const n = 9;
  const [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `(${ddd}) ${prefix}${n1}${n2}${n3}${n4}-${n5}${n6}${n7}${n8}`;
  } else {
    return `${ddd}${prefix}${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const generateRG = (masked: boolean = false) => {
  const total_array = 8;
  const n = 9;
  const [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `${n1}${n2}.${n3}${n4}${n5}.${n6}${n7}${n8}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const generateZipcode = (masked: boolean = false) => {
  const total_array = 8;
  const n = 9;
  const [n1, n2, n3, n4, n5, n6, n7, n8] = create_array(total_array, n);

  if (masked) {
    return `${n1}${n2}${n3}${n4}${n5}-${n6}${n7}${n8}`;
  } else {
    return `${n1}${n2}${n3}${n4}${n5}${n6}${n7}${n8}`;
  }
};

export const generateId = (size: number = 6) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < size) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getDateInfo = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Mês é baseado em zero (0-11), então adicionamos 1.
  const firstDayOfMonth = 1;
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

  return {
    firstDayOfMonth,
    lastDayOfMonth,
    currentMonth,
    currentYear,
  };
};

export const obscureEmail = (email: string): string => {
  const [username, domain] = email.split("@");

  if (username.length < 3) {
    return email;
  }

  const firstTwoChars = username.slice(0, 2);

  const lastTwoChars = username.slice(-2);

  const obscuredEmail = `${firstTwoChars}**********${lastTwoChars}@${domain}`;

  return obscuredEmail;
};

export const optionsPhoneCode = [{ label: "+55", value: "1" }];

export const contactType = [
  {
    label: "Cliente",
    value: "customer",
  },
  {
    label: "Fornecedor",
    value: "supplier",
  },
  {
    label: "Prestador de serviço",
    value: "service_provider",
  },
  {
    label: "Funcionário",
    value: "employee",
  },
  {
    label: "Diretoria",
    value: "management",
  },
];

export const formatCPFCNPJ = (value: string): string => {
  if (!value) return value;

  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length === 11) {
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cleanedValue.length === 14) {
    return cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return value;
};

export const timeAgo = (date: string) => {
  const newDate = new Date(date);

  const relativeTime = formatDistanceToNow(newDate, {
    addSuffix: true,
    locale: ptBR,
  });

  return relativeTime;
};

export const calculateSla = (
  slaValue: number,
  dateStr: string,
  type?: string
) => {
  const now = new Date();
  const startDate = new Date(dateStr);

  let slaHours: number;
  switch (type) {
    case "day":
      slaHours = slaValue * 24; // 1 dia = 24 horas
      break;
    case "minute":
      slaHours = slaValue / 60; // 1 hora = 60 minutos
      break;
    default:
      slaHours = slaValue; // tipo 'hour' ou padrão
  }

  const endDate = addHours(startDate, slaHours);

  // Calcular a diferença entre agora e a data de término do SLA
  let duration;
  let status;

  if (isAfter(now, endDate)) {
    // Atrasado
    duration = intervalToDuration({ start: endDate, end: now });
    status = "atrasado";
  } else {
    // Dentro do prazo
    duration = intervalToDuration({ start: now, end: endDate });
    status = "noPrazo";
  }

  // Formatar a duração manualmente
  const formattedDuration = [];
  if (duration.years) formattedDuration.push(`${duration.years}a`);
  if (duration.months) formattedDuration.push(`${duration.months}m`);
  if (duration.days) formattedDuration.push(`${duration.days}d`);
  if (duration.hours) formattedDuration.push(`${duration.hours}h`);
  if (duration.minutes) formattedDuration.push(`${duration.minutes}min`);

  return {
    status,
    formattedDuration: formattedDuration.join(" "),
  };
};



export function getFormattedDateTime() {
  const now = new Date();

  // Obtém os componentes da data
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Mês é 0-indexado
  const year = now.getFullYear();

  // Obtém os componentes da hora
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Formata a data e a hora
  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

console.log(getFormattedDateTime());



export function formatarDataISO(dataISO: string): string {
  const data = new Date(dataISO);

  if (isNaN(data.getTime())) {
    throw new Error("Formato de data inválido.");
  }

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
  const ano = data.getFullYear();
  const horas = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');
  const segundos = String(data.getSeconds()).padStart(2, '0');

  return `${dia}/${mes}/${ano} às ${horas}:${minutos}:${segundos}`;
}



export function getFormattedDate() {
  const currentDate = new Date();

  // Obtém a data no formato ISO padrão
  const isoString = currentDate.toISOString();

  // Obtém os milissegundos e ajusta para microssegundos
  const milliseconds = currentDate.getMilliseconds();
  const microseconds = String(milliseconds).padStart(6, '0'); // Preenche até 6 dígitos

  // Substitui a parte dos milissegundos pela versão com microssegundos
  const formattedDate = isoString.replace(/\.\d{3}Z$/, `.${microseconds}Z`);

  return formattedDate;
}


export function addHoursToDate(horasString: string, dataISO: string) {
  // Extrai o número de horas da string (exemplo: "72_horas" -> 72)
  const horas = parseInt(horasString.split("_")[0], 10);

  // Converte a string de data ISO em um objeto Date
  const data = new Date(dataISO);

  // Adiciona as horas em milissegundos
  data.setHours(data.getHours() + horas);

  // Formata a data no formato DD/MM/YYYY
  const novoDia = String(data.getDate()).padStart(2, "0");
  const novoMes = String(data.getMonth() + 1).padStart(2, "0");
  const novoAno = data.getFullYear();

  // Formata o horário no formato HH:mm:ss
  const novaHora = String(data.getHours()).padStart(2, "0");
  const novoMinuto = String(data.getMinutes()).padStart(2, "0");
  const novoSegundo = String(data.getSeconds()).padStart(2, "0");

  return `${novoDia}/${novoMes}/${novoAno} ${novaHora}:${novoMinuto}:${novoSegundo}`;
}



export const formatarDataAtual = () => {
  const dataAtual = new Date();
  return dataAtual.toISOString();
};

