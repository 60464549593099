import { Button, Tab, TabPane } from "prosperita-dumbo-react"
import { Topical } from "../Topical/Topical"
import { filterIcon } from "~/assets/IconsSVG"
import { addIconSVG } from "~/assets/IconsSVG"
import { useState } from "react"
import useForum from "~/hooks/useForum"
import { useNavigate } from "react-router-dom"

export type ActivitiesProps = {
    userNotIvite: boolean;
}

export const Activities = ({ userNotIvite }: ActivitiesProps) => {

    const [tab, setTab] = useState<"topical">("topical")

    const navigate = useNavigate()

    const { listForumFiltered, setFilterForumTopic } = useForum()

    const response =
        [
            {
                label: "Topico",
                tab: "topical",
                width: "100px",
                content: <Topical userNotIvite={userNotIvite} />
            }
        ]

    return (
        <div>
            <div style={{ borderRadius: "10px" }}
                className="bg-[#f4f4f4] mx-auto w-[100%] p-[20px]">
                <h4>Descrição</h4>
                <p>{listForumFiltered?.description}</p>
            </div>
            <div className="w-[100%] flex gap-[10px] justify-end mt-[80px] items-center">
                <div
                    style={{ cursor: "pointer" }}
                    className=" w-[50px] py-[14px] text-center"
                    onClick={() => setFilterForumTopic((prev) => !prev)}>
                    <section className="mx-auto flex justify-center">{filterIcon}</section>
                </div>
                <Button
                    label="Criar novo tópico"
                    kind="tertiary"
                    size="large"
                    hasIconSvg
                    icon={addIconSVG}
                    disabled={userNotIvite}
                    onClick={() => navigate("/app/forum/new-topic/", { state: { id: listForumFiltered?.id } })}
                />
            </div>
            <div className="w-[100%]">
                <Tab width="100%">
                    {response.map((render) => (
                        <TabPane
                            position="center"
                            isActive={tab === "topical"}
                            label={render.label}
                            width={render.width}
                            bordercolor=" rgb(10, 74, 135)"
                            onClick={() => setTab("topical")}
                        >
                            {render.content}
                        </TabPane>
                    ))}

                </Tab>
            </div>
        </div>
    )
}
