import { Button, TableBasic } from "prosperita-dumbo-react"
import { ItypeValidate } from "./CreatForumGeneral"
import { TextFirst } from "./SettingsForum"
import { useCallback, useEffect, useMemo } from "react"
import { getFormattedDateTime } from "~/services/utils"
import { ModalInviteParticipant } from "./ModalInviteParticipant"
import useForum from "~/hooks/useForum"
import { getTypeUserRole } from "~/services/api/forum"
import useLoading from "~/hooks/useLoading"
import trashIcon from "~/assets/trashIcon.png"
import { ModalRemoveRoleUser } from "../TypeForum/ModalRemoveRoleUser"
import useUser from "~/hooks/useUser"


export const SettingsParticipants = ({ onValidate, formik }: ItypeValidate) => {

  onValidate(true)

  const { values } = formik

  const { setLoading } = useLoading();

  const {
    userProfile,
  } = useUser();

  const {
    setmodalInviteParticipant,
    modalInviteParticipant,
    participantList,
    setParticipantList,
    setOnlyParticipant,
    setModalremoveParticipantTypeForum,
    modalremoveParticipantTypeForum,
  } = useForum()

  const voteDict: { [key: string]: string } = {
    vota: "Vota",
    nao_vota: "Não Vota",
    voto_com_veto: "Vota com Veto"
  }

  const fetchParticipantMandatoryForum = useCallback(async () => {

    setLoading(true);

    try {

      const { data } = await getTypeUserRole(Number(values.type_forum))

      setParticipantList(data.results)

    } catch {

      setParticipantList([])

    } finally {

      setLoading(false);
    }

  }, [participantList])


  const listProfilesParticipants = useMemo(() => {

    return participantList?.map((users) => ({
      "Nome":
        <div className="flex gap-[5px]">
          <p className="p-0 m-0">{users.user.first_name}</p>
          <p className="p-0 m-0">{users.user.last_name}</p>
        </div>,
      "Tipo de participante": users.user_status,
      "Tipo de voto": voteDict[users.type_vote],
      delete: <div className="w-[20px]">
        <Button
          kind="ghost"
          size="small"
          noPadding="2px"
          borderNone
          icon={trashIcon}
          onClick={() => {
            setOnlyParticipant(
              {
                "id": String(users.id),
                "type_vote": users.type_vote,
                "first_name": users.user.first_name,
                "last_name": users.user.last_name,
                "id_number": String(users.id),
                "created_on": users.created_on,
                "forum_type": String(users.forum_type)
              }
            )
            setModalremoveParticipantTypeForum(true);
          }
          }
        />
      </div>
    }))
  }, [participantList])

  useEffect(() => {
    fetchParticipantMandatoryForum()
  }, [values.type_forum])

  return (
    <div>
      <div className="w-[100%] flex justify-between my-[20px] p-0">
        <section className="m-[0] p-0">
          <TextFirst>Solicitante</TextFirst>
          <h6 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[14px] font-normal leading-[18px] tracking-[0.16px]">{userProfile?.name}</h6>
        </section>
        <section className="m-[0] p-0">
          <TextFirst>Data</TextFirst>
          <h6 className="text-[var(--Text-text-primary,#161616)] font-['IBM_Plex_Sans'] text-[14px] font-normal leading-[18px] tracking-[0.16px]">{getFormattedDateTime()}</h6>
        </section>
      </div>
      <h4 className="text-[20px] leading-[1.4] text-black font-ibm-plex-sans font-normal  my-[20px]">Configurações dos participantes</h4>
      <TableBasic
        label="Participantes"
        search
        columnTitles={["Nome", "Tipo de participante", "Tipo de voto", ""]}
        pageSizes={[10, 20, 2, 8]}
        rowData={listProfilesParticipants || []}
        columnSizes="5px"
        buttons={[{
          label: "Convidar participante",
          kind: "primary",
          position: "right",
          onClick: () => setmodalInviteParticipant(true)
        }]}
      />
      <ModalInviteParticipant
        forum_type={values.type_forum}
        open={modalInviteParticipant}
        onClose={() => setmodalInviteParticipant(false)} />
      <ModalRemoveRoleUser
        forum_type={values.type_forum}
        open={modalremoveParticipantTypeForum}
        onClose={() => setModalremoveParticipantTypeForum(false)} />
    </div>
  )
}
