import { IFile } from "~/types";
import ApiService from "../api";

type IResponseAttendTaskFiles = {
  count: number;
  previous: null;
  next: null;

  results: IFile[];
};

// type IBodySendAttendTaskFile = {
//   attend?: number | undefined;
//   task?: number;
//   file: File | unknown;
//   description?: string;
//   sender: number | undefined;
//   target: number | undefined;
// };

export const getAttendTaskFileAPI = () => {
  return ApiService.HttpGet<IResponseAttendTaskFiles>({
    route: "attend/attend-task-file/",
    token: true,
    params: { expand: ["sender"] },
  });
};


export const getAttendTaskFileForumAPI = (attend: number) => {
  return ApiService.HttpGet<IResponseAttendTaskFiles>({
    route: "attend/attend-task-file/",
    token: true,
    params: { attend }
  });
};

export const postAttendTaskFileAPI = (body: unknown) => {
  return ApiService.HttpPost<IFile>({
    route: "attend/attend-task-file/",
    customHeaders: {
      "Content-Type": "multipart/form-data",
    },
    body: body,
    token: true,
  });
};

export const deleteAttendTaskFileAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `attend/attend-task-file/${id}/`,

    token: true,
  });
};

// export const patchAttendLogAPI = (body: IUpdateAttendLog, id: number) => {
//   return ApiService.HttpPost<IAttendLog>({
//     route: `attend/attend-log/${id}/`,
//     customHeaders: { Authorization: `Bearer ${token}` },
//     body: body,
//   });
// };
