import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Dropdown,
  InputFluid,
  Modal,
  Notification,
} from "prosperita-dumbo-react";
import addicon from "~/assets/iconadd.png";
import Margin from "~/components/Margin";
import {
  getProfilesAPI,
  IPostProfileBody,
  PostProfileAPI,
} from "~/services/api/profile";
import { onlyNumbers } from "~/services/utils";
import useLoading from "~/hooks/useLoading";
import { getCompanysAPI } from "~/services/api/companys";
import useCompany from "~/hooks/useCompany";
import useProfile from "~/hooks/useProfile";
import { ModalAddConfirmedProfile } from "./ConfirmedModalAddProfile";

// Tipos
type IModal = {
  open: boolean;
  onClose?: () => void;
};

type Values = {
  full_name: string;
  document_number: string;
  company_name: string;
  company_document_number: string;
  brand_name: string;
  email: string;
  password: string;
  confirm_password: string;
  company: string;
  type_profile: string;
};

type Idropdown = {
  value: string;
  label: string;
};

type INotification = {
  status: "success" | "warning" | "info" | "error";
  message: string;
  title: string;
  active: boolean;
};

// Opções do Dropdown
const options: Idropdown[] = [
  { value: "tool_manager", label: "Gestor de ferramenta" },
  { value: "area_manager", label: "Gestor de área" },
  { value: "internal", label: "Atendente" },
];

// Valores iniciais
const initialValues: Values = {
  full_name: "",
  document_number: "",
  company_name: "",
  company_document_number: "",
  brand_name: "",
  email: "",
  password: "",
  confirm_password: "",
  company: "",
  type_profile: "",
};

// Validação
const validationSchema = Yup.object({
  full_name: Yup.string().required("Nome completo é obrigatório"),
  document_number: Yup.string()
    .required("Número do documento é obrigatório"),
  company_name: Yup.string().required("Nome da empresa é obrigatório"),
  company_document_number: Yup.string()
    .matches(
      /^\d+$/,
      "Número do documento da empresa deve conter apenas números"
    )
    .required("Número do documento da empresa é obrigatório"),
  brand_name: Yup.string().required("Nome da marca é obrigatório"),
  email: Yup.string()
    .email("Email inválido")
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Email deve ser um formato válido"
    )
    .required("Email é obrigatório"),
  password: Yup.string()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
    .matches(/\d/, "A senha deve conter pelo menos um número")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "A senha deve conter pelo menos um caractere especial"
    )
    .required("Senha é obrigatória"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "As senhas devem corresponder")
    .required("Confirmação de senha é obrigatória"),
  type_profile: Yup.string().required("Tipo de usuário é obrigatório"),
});

export const AddProfileModal = ({ open, onClose }: IModal) => {

  const { setLoading } = useLoading();

  const [clearFilesDrop, setclearFilesDrop] = useState<boolean>(false)

  const {
    setCreatePasswordModalOpen,
    setIsProfileFormOpenModal,
    confirmedModalAddProfile,
    setConfirmedModalAddProfile,
    setProfiles,
  } = useProfile();

  const { setCompanys, companys } = useCompany();

  const [notificationDetails, setNotificationDetails] = useState<INotification>(
    {
      status: "info",
      message: "",
      title: "",
      active: false,
    }
  );
  const fetchCompanys = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setCompanys]);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setProfiles]);

  const companysOptions = useMemo(() => {
    return companys
      .map((company) => ({
        value: String(company.id),
        label: company?.raw_data?.brand_name?.trim() || "",
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [companys]);

  const { values, handleChange, errors, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      ("");
    },
  });

  const payload: IPostProfileBody = useMemo(
    () => ({
      name: values.full_name,
      document_number: onlyNumbers(values.document_number),
      type: [values.type_profile],
      email: values.email,
      raw_data: { first_login: true },
      company: Number(values.company),
    }),
    [values]
  );

  const disabledButtonWhenTypeIsClient = useMemo(() => {

    const isFullNameValid = payload.name && payload.name.trim().split(/\s+/).length >= 2;

    const isDocumentNumberValid = payload.document_number.length >= 11 && payload.document_number.length < 23;

    const isEmailValid = Boolean(payload.email) && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(payload.email);

    const isTypeProfileValid = payload.type && payload.type[0];

    return (
      !isFullNameValid ||
      !isDocumentNumberValid ||
      !isEmailValid ||
      !isTypeProfileValid
    );
  }, [payload]);

  const createProfile = useCallback(async () => {
    setLoading(true);
    setCreatePasswordModalOpen(false);
    try {
      await PostProfileAPI(payload);
      setNotificationDetails({
        status: "success",
        message:
          "O usuário foi adicionado com sucesso e o e-mail com suas credenciais foi enviado com sucesso.",
        title: "Usuário adicionado com sucesso",
        active: true,
      });
      resetForm();
      fetchProfiles();

    } catch (err) {
      setNotificationDetails({
        status: "error",
        message:
          "Não foi possível adicionar o usuário no momento, tente novamente mais tarde ou contate o administrador do sistema",
        title: "Falha ao adicionar usuário",
        active: true,
      });
      console.error(err);
    } finally {
      setLoading(false);
      setConfirmedModalAddProfile(false);
      setTimeout(() => {
        setNotificationDetails((prev) => ({ ...prev, active: false }));
      }, 5000);
      setclearFilesDrop(true)
    }
  }, [payload, setLoading, setCreatePasswordModalOpen, fetchProfiles]);

  const handleModalSubmit = () => {
    setIsProfileFormOpenModal(false);
    setConfirmedModalAddProfile(true);
  };

  useEffect(() => {
    fetchCompanys();
    fetchProfiles();
  }, [fetchCompanys, fetchProfiles]);


  useEffect(() => {
    if (clearFilesDrop) {
      setclearFilesDrop(false);
    }
  }, [values, clearFilesDrop]);
  return (
    <>
      <Modal
        onClose={() => {
          onClose && onClose()
          setclearFilesDrop(true)
          resetForm();
        }}
        open={open}
        height="600px"
        width="50%"
        overflow
        label={
          <div className="h-[90px]">
            <h4 className="mt-2 p-0">Adicionar usuário</h4>
            <div
              style={{
                transform: "translateY(-10px)",
                position: "absolute",
                right: "0",
                left: "0",
                top: "120px",
                zIndex: "100",
              }}
              className="mt-0 p-0 bg-[#c6c6c6] w-[100%] h-[2px]"
            ></div>
          </div>
        }
        background="#f4f4f4"
        buttons={[
          {
            label: "Cancelar",
            kind: "ghost",
            onClick: () => {
              setIsProfileFormOpenModal(false);
              resetForm();
              setclearFilesDrop(true)
            },
          },
          {
            label: "Adicionar usuário",
            icon: addicon,
            kind: "primary",
            disabled: disabledButtonWhenTypeIsClient,
            onClick: () => {
              handleModalSubmit()
            },
          },
        ]}
      >
        <div className="w-[98%] mx-auto m-0 p-0">
          <Margin mt={10} />
          <Dropdown
            kind="fluid"
            label="Tipo de perfil (obrigatório)"
            options={options}
            size="large"
            name="type_profile"
            placeholder="Selecione o perfil"
            noRadius
            onChange={(e) => handleChange(e)}
            borderNone
            clearFiles={clearFilesDrop}
          />
          <InputFluid
            label="Nome Completo (obrigatório)"
            borderNone
            noRadius
            id="full_name"
            onChange={handleChange}
            name="full_name"
            placeholder="Digite o nome"
            value={values.full_name}
            message={errors.full_name}

          />
          <div className="text-red-500 text-sm mt-1 flex align-center">
            {errors.full_name && "* " + errors.full_name}
          </div>
          <div className="flex justify-center mt-1 gap-[10px] ">
            <div className="w-[100%]">
              <InputFluid
                label="CPF (obrigatório)"
                borderNone
                noRadius
                maxLength={20}
                id="document_number"
                onChange={handleChange}
                name="document_number"
                placeholder="000.000.000-00"
                value={values.document_number}
                message={errors.document_number}
              />
              <div className="text-red-500 text-sm mt-1 flex align-center">
                {errors.document_number && "* " + errors.document_number}
              </div>
            </div>
            <div className="w-[100%]">
              <InputFluid
                label="email (obrigatório)"
                borderNone
                noRadius
                maxLength={66}
                id="email"
                onChange={handleChange}
                name="email"
                placeholder="exemplo@exemplo.com"
                value={values.email}
                message={errors.email}
              />
              <div className="text-red-500 text-sm mt-1 flex align-center">
                {errors.email && "* " + errors.email}
              </div>
            </div>
            <Margin mt={10} />
          </div>
          <Margin mt={10} />
          <Dropdown
            kind="fluid"
            label="Vincular empresa"
            options={companysOptions}
            size="large"
            name="company"
            placeholder="Selecione empresa"
            noRadius
            onChange={(e) => handleChange(e)}
            clearFiles={clearFilesDrop}
            borderNone
          />
          <Margin mt={53} />
        </div>
      </Modal>
      <Notification
        zindex={notificationDetails.status === "error" ? "10" : "8"}
        top={"130px"}
        right="20px"
        onClose={() =>
          setNotificationDetails((prev) => ({
            ...prev,
            active: false,
          }))
        }
        noRadius
        status={notificationDetails.status}
        actived={notificationDetails.active}
        title={notificationDetails.title}
        message={notificationDetails.message}
      ></Notification>
      <ModalAddConfirmedProfile
        open={confirmedModalAddProfile}
        onClose={() => setConfirmedModalAddProfile(false)}
        onConfirm={() => createProfile()}
      />
    </>
  );
};
