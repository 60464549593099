import { Modal } from 'prosperita-dumbo-react'
import useForum from '~/hooks/useForum'

type ItypeModal = {
    open: boolean,
    onClose: () => void,
}

function getFileNameFromUrl(url: string) {

    const parsedUrl = new URL(url);

    const pathName = parsedUrl.pathname;

    const fileName = pathName.substring(pathName.lastIndexOf('/') + 1);

    return fileName;
}

function getFileExtension(filename: string) {
    return filename.split('.').pop();
}


export const ModalDocument = ({ onClose, open }: ItypeModal) => {

    const {
        modalDocumentData
    } = useForum()

    return (
        <div>
            <Modal
                label={""}
                open={open}
                height={"580px"}
                width="45%"
                onClose={() => {
                    onClose && onClose();
                }}

                background="#ffffff"
                buttons={[
                    {
                        label: "Cancelar",
                        kind: "dangerPrimary",
                        onClick: () => { onClose && onClose(); }

                    }
                ]}
            >
                {modalDocumentData && getFileExtension(getFileNameFromUrl(modalDocumentData?.file)) === "pdf" ? (
                    <iframe
                        src={modalDocumentData?.file}
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                    />
                ) : (
                    <img
                        src={modalDocumentData?.file}
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                )}
            </Modal>
        </div>
    )
}
